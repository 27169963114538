import { useFormik } from "formik";
import _, { size } from "lodash";
import moment from "moment";
import {
  default as React,
  default as react,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import { Tooltip } from "react-tooltip";
import CheckSVG from "../../../assets/media/icons/other_icons/check.svg";
import WarningIcon from "../../../assets/media/icons/standard_icons/warning.svg";

import * as Yup from "yup";
import CloneSVG from "../../../assets/media/icons/other_icons/Clone.svg";
import UploadSVG from "../../../assets/media/icons/other_icons/Upload.svg";
import UploadSVGNew from "../../../assets/media/icons/other_icons/Upload_new.svg";
import AddSVGGray from "../../../assets/media/icons/other_icons/add_icon-gray.svg";
import AddSVG from "../../../assets/media/icons/other_icons/add_icon.svg";
import externalLink from "../../../assets/media/icons/other_icons/external-link.svg";
import fullSVG from "../../../assets/media/icons/other_icons/full-s.svg";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";

import LockIconOpen from "../../../assets/media/icons/other_icons/lock-open-solid.svg";
import LockIcon from "../../../assets/media/icons/other_icons/lock-solid.svg";
import processSVG from "../../../assets/media/icons/other_icons/process.svg";
import ReloadIconSVG from "../../../assets/media/icons/other_icons/refresh.svg";
import {
  default as CrossSVG,
  default as crossArrow,
} from "../../../assets/media/icons/standard_icons/cross.svg";

import { LayoutContext } from "../../../components/core/LayoutProvider";
import DatePickerField from "../../../components/formComponent/DatePickerField";
import TextInput from "../../../components/formComponent/TextInput";
import TableDropdown from "../../../components/tableComponent/TableDropdown";
import { IKTSVG } from "../../../components/ui/IKTSVG";

import assignIcon from "../../../assets/media/icons/other_icons/assignIcon.svg";
import assignGray from "../../../assets/media/icons/other_icons/assignIconGray.svg";

import EditSVG from "../../../assets/media/icons/other_icons/edit-square.svg";
import PriceField from "../../../components/formComponent/PriceField";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { base64ToPDF } from "../../../helpers/AssetHelpers";
import {
  daysDifference,
  decimalNumber,
  getOrderStatusColor,
  getProperTicketTypeWithSubticketCombine,
  handleKeyDown,
  isValidNumber,
  parseMMDDYYYY,
  removeCommaFromNumber,
  ticketTypeRename,
  ticketTypeRenameForSalesReport,
} from "../../../helpers/Functions";
import Messages from "../../../helpers/Messages";
import { showAlert } from "../../../helpers/ShowAlert";
import {
  ORDER_ASSIGN_TO_NEW_LISTING,
  ORDER_ASSIGN_TO_NEW_TEAM,
  SYMBOLS,
} from "../../../helpers/const";
import { copy } from "../../add_inventory/core/_functions";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import NavigationButtons from "../component/NavigationButtons";
import OrderNewListing from "../component/orderReplacement/OrderNewListing";
import OrderNewTeam from "../component/orderReplacement/OrderNewTeam";
import { SalesContext } from "../core/SalesProvider";
import { display } from "../core/_functions";
import {
  addOrderNote,
  cancelAndRefund,
  changeTicketType,
  deleteOrderNote,
  salesDetailsView,
  suggestListingReplacement,
  updateCustomerDetails,
} from "../core/_requests";
import FullOrderDetail from "./FullOrderDetail";
import PopUpShimmer from "./PopUpShimmer";

export const isValidRefundAmount = (num: any) => {
  if (!num) return true;

  const regex = /[\s,](\..*){2,}/;
  return isValidNumber(num) && regex.test(num);
};

const isValidPriceFormat = (amt: any) => {
  if (!amt) return false;
  let regex = /^((\d+)((,\d+|\d+)*)(\s*|\.(\d{2}))$)/;
  let commaRemovedAmount = removeCommaFromNumber(amt);
  return regex.test(commaRemovedAmount);
};

const DETAILWISE_ERRROR: any = {
  setIsPenaltyLock: ["note_penalty", "fine_amount"],
  setIsCustomerLock: ["customer_email", "customer_contact_number"],
  setIsTicketLock: ["items"],
};

const OrderDetail = ({
  show,
  tabValue,
  handleShow,
  handleOrder,
  canvasRef,
  showOrder,
  isGodAdmin,
  optionsData,
  setShowOrder = () => {},
  teamsOptions,
  refetchListing,
}: any) => {
  const [orderDetails, setOrderDetails] = useState<any>();
  const [copyData, setCopyData] = useState<any>(undefined);
  const [extrasSuggestListing, setExtrasSuggestListing] = useState<any>();

  const layout = useContext(SalesContext);
  const coreLayout = useContext(LayoutContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [isCustomerLock, setIsCustomerLock] = useState<boolean>(true);
  const [isTicketLock, setIsTicketLock] = useState<boolean>(true);
  const [isAddressLock, setIsAddressLock] = useState<boolean>(true);
  const [isStatusLock, setIsStatusLock] = useState<boolean>(true);
  const [isNoteLock, setIsNoteLock] = useState<boolean>(true);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [buttonShow, setButtonShow] = useState<boolean>(false);
  const [isTicketTypeChange, setIsTicketTypeChange] = useState<boolean>(false);
  const [ticketTypeOptions, setTicketTypeOptions] = useState<any>();
  const [ticketTypeValue, setTicketTypeValue] = useState<any>(null);
  const [progressLoader, setProgressLoader] = useState<boolean>(false);

  const [isadministrationLock, setIsadministrationLock] = useState<any>({
    id: "",
    status: true,
  });
  const [isPenaltyLock, setIsPenaltyLock] = useState<boolean>(true);
  const [updateLoader, setUpdateLoader] = useState<Boolean>(false);
  const [orderNotes, setOrderNotes] = useState<any>(show?.data?.history);
  const [ledgerNote, setLedgerNote] = useState<any>("");
  const [ledgerNoteFocused, setLedgerNoteFocused] = useState(false);
  const [orderText, setOrderText] = useState<any>({
    show: false,
    note: "",
    orderType: !isGodAdmin ? "external" : "internal",
    confirmed: false,
  });
  const [cancelOrder, setCancelOrder] = useState({
    cancelOrderBtn: false,
    priceLock: true,
  });

  const [refundAmount, setRefundAmount] = useState<any>({
    actualRefundAmount: 0,
    amount: "",
  });

  const [error, setError] = useState<any>(null);

  const [refundBreakdown, setRefundBreakdown] = useState<any>([]);
  const [tixstockCurrencyBalances, setTixstockCurrencyBalances] = useState<any>(
    []
  );
  const [noteLoader, setNoteLoader] = useState<any>(false);

  const availableBalance =
    tixstockCurrencyBalances && size(tixstockCurrencyBalances) > 0
      ? tixstockCurrencyBalances?.find(
          (balance: any) => balance.currency === orderDetails?.refund_currency
        )?.balance
      : "0";

  const paymentMethod = refundBreakdown?.some(
    (breakDown: any) =>
      breakDown?.key === "payment_method" && breakDown?.value === "Card"
  );

  const ERROR_WISE_UNLOCK: any = {
    setIsPenaltyLock,
    setIsCustomerLock,
    setIsTicketLock,
  };

  // const orderStatusOptions: any = optionsData?.data?.order_statuses
  //   ?.flatMap((group: any) => group.data)
  //   ?.map((option: any) => {
  //     const descriptionType = `${option?.type || ""} - ${
  //       option?.description || ""
  //     }`;
  //     return {
  //       ...option, // Spread the existing properties
  //       descriptionType, // Add the new key
  //     };
  //   });
  const {
    isLoading: isDetailsLoading,
    data: detailsListingData,
    refetch,
    isFetching: isDetailFetching,
  } = useQuery(
    [`sales-details`, show?.data?.order_id],
    () => salesDetailsView({ order_id: show?.data?.order_id, tabValue }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: show?.data?.order_id ? true : false,
      // staleTime: 0,
      cacheTime: 0,
      onSettled(data, error) {
        if (orderDetails?.isChangeTicketType !== true) {
          setExtrasSuggestListing(undefined);
          setIsTicketLock(true);
          setIsCustomerLock(true);
          setIsAddressLock(true);
          setIsNoteLock(true);
          setIsPenaltyLock(true);
          setLedgerNoteFocused(false);
          setIsadministrationLock({
            id: "",
            status: true,
          });
        } else {
          setOrderDetails((pre: any) => ({
            ...pre,
            isChangeTicketType: false,
          }));
        }
      },
    }
  );

  useEffect(() => {
    if (detailsListingData) {
      const data = detailsListingData?.data?.[0];
      const amount = data?.refund_amount;
      setOrderDetails(data);
      setRefundAmount({
        actualRefundAmount: amount ? decimalNumber(amount) : amount,
        amount: amount ? decimalNumber(amount) : amount,
      });
      setRefundBreakdown(data?.refund_breakdown);
      setTixstockCurrencyBalances(data?.tixstock_currency_balances);

      // RENAME TICKET TYPE DROPDOWN OPTIONS
      const ticketType = getProperTicketTypeWithSubticketCombine(
        data?.ticket_types
      );
      const newRenameTicketType = ticketType?.map((obj: any) => {
        return {
          ...obj,
          name: ticketTypeRename(obj?.id),
        };
      });
      let updatedTicketTypes = _.orderBy(
        newRenameTicketType,
        ["name"],
        ["asc"]
      );

      const allowTicketTypeChange = data?.allow_ticket_type_change === 1;
      setIsTicketTypeChange(
        (tabValue === "new" || tabValue === "to_be_fulfilled") &&
          allowTicketTypeChange
      );

      setIsEditable(false);
      setButtonShow(false);
      if (updatedTicketTypes.length > 0) {
        setTicketTypeOptions(updatedTicketTypes);
      }

      if (data?.history) {
        setOrderNotes(data?.history);
      }

      // UPDATE LISTING STATE FOR TICKET TYPE
      layout?.setOrders((pre: any) => {
        return pre?.map((obj: any) => {
          if (obj?.order_id === data?.order_id) {
            return {
              ...obj,
              ticket_type: data?.ticket_type,
              sub_ticket_type: data?.sub_ticket_type,
            };
          } else {
            return obj;
          }
        });
      });
    }
  }, [detailsListingData]);

  useEffect(() => {
    const { actualRefundAmount, amount } = refundAmount;
    const formattedAmount = Number(removeCommaFromNumber(amount));
    const formattedActualRefundAmount = Number(
      removeCommaFromNumber(actualRefundAmount)
    );

    let newError = "";

    if (!isValidNumber(amount) || amount === "") {
      newError = "The refund amount must be a valid value";
    } else if (formattedAmount > availableBalance) {
      newError = "Insufficient balance in TX trade wallet.";
    } else if (formattedAmount < 0) {
      newError = "Refund amount must not be less than zero";
    } else if (formattedAmount > formattedActualRefundAmount) {
      newError =
        "The refund amount must not exceed the original payment amount.";
    }
    setError(newError);
  }, [availableBalance, refundAmount]);

  const handleDropdownChange = (selectedOption: any) => {
    setTicketTypeValue(selectedOption.target.value);
    setButtonShow(true);
  };

  const handleConfirm = async (orderId: any, ticketId: any) => {
    setProgressLoader(true);
    try {
      const response = await changeTicketType(orderId, ticketId);
      if (response?.data?.status) {
        setOrderDetails((pre: any) => ({
          ...pre,
          isChangeTicketType: true,
        }));
        showAlert(response.message, false);
        setTicketTypeValue(null);
        setButtonShow(false);
        setIsEditable(false);
        refetch();
      } else {
        showAlert(response.errors, true);
      }
      setProgressLoader(false);
    } catch (error) {
      console.error(error);
      showAlert("An error occurred. Please try again.", true);
    }
  };

  const handleCancelOrder = async (orderId: any, refund_amount: any) => {
    setProgressLoader(true);
    const { actualRefundAmount, amount } = refund_amount;

    const formattedAmount = Number(removeCommaFromNumber(amount));
    const formattedActualRefundAmount = Number(
      removeCommaFromNumber(actualRefundAmount)
    );

    try {
      if (!isValidNumber(amount) || amount === "") {
        showAlert("The refund amount must be a valid value", true);
      } else if (formattedAmount > availableBalance) {
        showAlert("Insufficient balance in TX trade wallet.", true);
      } else if (formattedAmount < 0) {
        showAlert("Refund amount must not be less than zero.", true);
      } else if (formattedAmount > formattedActualRefundAmount) {
        showAlert(
          "The refund amount must not exceed the original payment amount.",
          true
        );
      } else if (paymentMethod && ledgerNote?.trim() === "") {
        showAlert("Ledger note is required.", true);
      } else {
        const response = await cancelAndRefund(
          orderId,
          formattedAmount,
          ledgerNote?.trim()
        );
        if (response?.data?.status) {
          showAlert(response.message, false);
          layout.setOrders((oldOrders: any[]) => {
            return oldOrders.filter(
              (order: any) => order.order_id !== show.data.order_id
            );
          });
          refetchListing();
          display(false);
          handleShow({
            show: false,
            data: "",
          });
        } else {
          let errorMsg =
            response && response?.errors?.title
              ? response?.errors?.title
              : size(response?.errors) > 0 && Array.isArray(response?.errors)
              ? response?.errors?.join("<br/>")
              : response?.message
              ? response?.message
              : "Something went wrong!";
          // let errorMsg = convertIntoErrorMessage(
          //   response?.errors ? response : response?.data
          // );
          showAlert(errorMsg, true);
        }
      }
      setProgressLoader(false);
    } catch (error) {
      console.log(error);
      showAlert("An error occurred. Please try again.", true);
    }
  };

  const [isCopied, setIsCopied] = useState<any>({
    id: "",
    value: "",
  });
  let orders = layout.orders;
  let copyShippingAddress =
    show &&
    `${orderDetails?.customer_shipping_address_line_1},  ${orderDetails?.customer_shipping_address_line_2}, ${orderDetails?.customer_shipping_town}, ${orderDetails?.customer_shipping_postcode}, ${orderDetails?.customer_shipping_country}`;
  let copyBillingAddress =
    show &&
    `${orderDetails?.customer_billing_address_line_1},  ${orderDetails?.customer_billing_address_line_2}, ${orderDetails?.customer_billing_town}, ${orderDetails?.customer_billing_postcode}, ${orderDetails?.customer_billing_country}`;

  const ticketItems =
    orderDetails?.items && orderDetails?.items?.length > 0
      ? orderDetails?.items
      : [];

  const checkShippingAddress = copyShippingAddress
    .replaceAll(",", "")
    .replaceAll(" ", "");

  const checkBillingAddress = copyBillingAddress
    .replaceAll(",", "")
    .replaceAll(" ", "");

  // HANDLE SHOW ORDER VIEW PAGE
  // const display = (isOpen: boolean) => {
  //   var pop_width = document.getElementsByClassName("s_ticket")[0].clientWidth;
  //   if (isOpen) {
  //     (document.getElementById("EventAccordion")?.style).marginRight = `${
  //       pop_width - 21
  //     }px`;
  //   } else if (!isOpen) {
  //     (document.getElementById("EventAccordion")?.style).marginRight = `0px`;
  //     const singleTicket = document.getElementById("singleTicket");
  //     if (singleTicket) {
  //       singleTicket.style.visibility = "hidden";
  //     }
  //   }
  // };

  // SHOW ORDER NOTE TEXT INPUT
  const HadleAddOrderNote = () => {
    setOrderText((prev: any) => {
      return {
        ...prev,
        show: true,
      };
    });
  };

  // HANDLE ONCHANGE TEXT INPUT
  const handleOrderText = (e: any) => {
    setOrderText((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target?.checked
          ? e.target?.checked
          : e.target?.value,
      };
    });
  };

  // ADD ORDER TEXT TO API
  const addOrderText = (e: any) => {
    e.preventDefault();
    let trimOrderText = orderText?.note?.trim();
    if (trimOrderText === "") {
      showAlert(Messages?.inValidOrderNote, true);
    } else {
      setNoteLoader(true);
      let payload: any = {
        order_id: show?.data?.order_id,
        note: trimOrderText,
        type: orderText?.orderType === "internal" ? 1 : 3,
      };
      addOrderNote(payload)
        .then((response: any) => {
          if (response?.data?.status === true) {
            showAlert("The order note has been successfully added.", false);
            response?.data?.history && setOrderNotes(response?.data?.history);
            setOrderDetails((pre: any) => ({
              ...pre,
              history: response?.data?.history,
            }));
            cancleOrderText();
          } else {
            if (response?.errors?.title) {
              showAlert(response?.errors?.title, true);
            } else {
              showAlert("Something went wrong.", true);
            }
          }
        })
        .finally(() => setNoteLoader(false));
    }
  };

  // CANCEL ORDER TEXT
  const cancleOrderText = () => {
    setOrderText((prev: any) => {
      return {
        ...prev,
        show: false,
        note: "",
        confirmed: false,
      };
    });
  };

  // DELETE ORDER TEXT
  const deleteOrderText = (data: any) => {
    setNoteLoader(true);
    let payload: any = {
      order_id: show?.data?.order_id,
      index: data?.index,
    };
    deleteOrderNote(payload)
      .then((response: any) => {
        if (response?.data?.id) {
          showAlert("The order note has been successfully deleted.", false);
          response?.data?.history && setOrderNotes(response?.data?.history);
          cancleOrderText();
        } else {
          if (response?.errors?.title) {
            showAlert(response?.errors?.title, true);
          } else {
            showAlert("Something went wrong.", true);
          }
        }
      })
      .finally(() => setNoteLoader(false));
  };

  // ADJUCT TABLE STYLING
  useEffect(() => {
    display(show?.show);
    setOrderNotes(show?.data?.history);
  }, [show]);

  // COPIED EFFECT
  useEffect(() => {
    setTimeout(() => {
      setIsCopied("");
    }, 2000);
  }, [isCopied]);

  // DETAIL INFO COMPONENT
  const DetailInfo = ({
    header,
    headerClassName = "text-gray-400 text-sm12",
    value,
    valueClassName = "text-sm12",
    isSingle = false,
    padding = "p-2",
  }: any) => {
    return (
      <div className={`md:w-1/2 w-full ${padding}`}>
        <div
          className={`${headerClassName} ${
            isGodAdmin && isSingle && "flex flex-inline"
          }`}
        >
          {header}
          {/* {isGodAdmin && isSingle && (
              <span
                className="ml-2 cursor-pointer"
                onClick={() => setIsStatusLock(!isStatusLock)}
              >
                <img
                  src={isStatusLock ? LockIcon : LockIconOpen}
                  alt="lockIcon"
                  className="!w-[.9375rem] !h-[.9375rem]"
                />
              </span>
            )} */}
        </div>
        {isSingle ? (
          // isGodAdmin && !isStatusLock ? (
          //   <div className="w-full py-3 text-sm12 border-violet-900 flex flex-wrap items-center relative">
          //     <TableDropdown
          //       options={orderStatusOptions}
          //       name="order_status"
          //       placeholder="Order status"
          //       isOrderStatusDropdown="true"
          //       value={orderStatusOptions.find(
          //         (option: any) =>
          //           option.description === formik?.values?.order_status
          //       )}
          //       isClearable={false}
          //       handleOnChange={(e: any) => {
          //         formik.setFieldValue(`order_status_id`, e.target.value?.id);
          //         formik.setFieldValue(
          //           `order_status`,
          //           e.target.value?.description
          //         );
          //       }}
          //       getOptionLabel="descriptionType"
          //       getOptionValue="id"
          //       isValueCapital={true}
          //       isCapital={true}
          //     />
          //   </div>
          // ) : (
          <div className={headerClassName}>
            <div
              className={`inline-flex px-2.5 py-[3px] ${statusColor} text-sm12 text-white rounded-l`}
            >
              {value}
            </div>
            <div
              className={`inline-flex px-2.5 py-[3px] ${dateColor} text-sm12 text-violet-800 rounded-r `}
            >
              {legacyCheck === "true" || legacyCheck === true ? (
                <span className="capitalize">Legacy order</span>
              ) : value === "Cancelled" &&
                orderDetails?.internal_order_status !== "" &&
                isGodAdmin ? (
                orderDetails?.internal_order_status
              ) : (
                ticketTypeRenameForSalesReport(
                  orderDetails?.ticket_type,
                  orderDetails?.sub_ticket_type
                )
              )}
            </div>
          </div>
        ) : (
          // )
          <div className={valueClassName}>{value}</div>
        )}
      </div>
    );
  };

  // COPY TO CLIPBOARD COMPONENT
  const CopyToClipboard = ({
    headerName,
    value,
    id,
    handleClick,
    padding = "p-2",
    margin = "mb-1",
  }: any) => {
    return (
      <>
        <div className={`md:w-1/2 ${padding} w-full`}>
          <div
            className={`text-gray-400 text-sm12 ${margin}`}
            title={headerName}
          >
            {headerName}
          </div>
          <div className="relative">
            <input
              type="text"
              value={value}
              className="text-sm12 rounded bg-gray-100 w-full border-none px-2 py-0 block text-violet-800"
              disabled
            />
            {value !== "" && (
              <button
                className="flex items-center justify-center p-1 absolute top-1/2 right-1 -translate-y-1/2"
                type="button"
                onClick={(e: any) => {
                  handleClick({ id, value });
                }}
              >
                <IKTSVG
                  className={`flex items-center justify-center transition
                      ${
                        isCopied.value === value && isCopied.id === id
                          ? "fill-green-600  hover:fill-green-600"
                          : "fill-violet-500 hover:fill-indigo-500"
                      }
                      `}
                  path={CloneSVG}
                  svgClassName="w-[.7813rem] h-[.6563rem]"
                />
                {/* {isCopied.value === value && isCopied.id === id ? (
                    <span className="text-xs">copied</span>
                  ) : (
                    <IKTSVG
                      className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition"
                      path={CloneSVG}
                      svgClassName="w-[.7813rem] h-[.6563rem]"
                    />
                  )} */}
              </button>
            )}
          </div>
        </div>
      </>
    );
  };

  function findNextUnlockedIndex(startIndex: any, nav: any) {
    let currentIndex = startIndex;

    do {
      if (nav === "back") {
        currentIndex =
          currentIndex === 0 ? orders.length - 1 : currentIndex - 1;
      } else {
        currentIndex =
          currentIndex === orders.length - 1 ? 0 : currentIndex + 1;
      }

      if (currentIndex === startIndex) {
        break;
      }
    } while (orders[currentIndex].isLock);

    return currentIndex;
  }

  // HANDLE NAVIGATION OF ORDER VIEW PAGE
  const handleNavigation = (nav: string) => {
    let newIndex = show?.index;
    setExtrasSuggestListing(undefined);
    if (isGodAdmin && tabValue === "to_be_fulfilled") {
      newIndex = findNextUnlockedIndex(newIndex, nav);
      setIsCustomerLock(true);
      setIsAddressLock(true);
      setIsTicketLock(true);
    } else {
      if (nav === "back") {
        newIndex = newIndex === 0 ? orders.length - 1 : newIndex - 1;
      } else {
        newIndex = newIndex === orders.length - 1 ? 0 : newIndex + 1;
      }
    }

    const preRecord = orders[newIndex];
    setOrderNotes([]);
    handleShow({
      show: true,
      data: preRecord,
      index: newIndex,
    });
  };

  const legacyCheck = orderDetails?.is_legacy_order;
  const isTxTradeOrder = orderDetails?.is_tx_trade_order;
  const showCancelOrderButton: any =
    isGodAdmin &&
    isTxTradeOrder &&
    ["to_be_fulfilled", "new"].includes(tabValue);
  // && false // disabled the cancel order

  // DOWNLOAD PDF
  async function downloadPDF(file: any, fileName: string) {
    try {
      setLoader(true);
      await Promise.resolve(base64ToPDF(file, fileName));
      setLoader(false);
    } catch (error) {
      console.error(error);
    }
  }

  // OPEN BLOB PDF IN NEW TAB
  function openBlobPDF(item: any) {
    const byteCharacters = atob(item);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a new blob from the byte array
    const blob = new Blob([byteArray], { type: "application/pdf" });

    // Create a new URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Open the URL in a new tab
    window.open(url, "_blank");

    // Clean up the URL object after opening the PDF
    window.URL.revokeObjectURL(url);
  }
  const PdfTickitCheck =
    orderDetails?.ticket_type === "PDF" ||
    orderDetails?.ticket_type === "eticket";
  //const legacyCheck = 'true'

  const detailsSchema = Yup.object().shape({
    customer_email: Yup.string().trim().email("Must be a valid email address"),
    customer_contact_number: Yup.string()
      .min(10, "Too short")
      .max(20, "Too long"),
    items: Yup.array().of(
      Yup.object().shape({
        customer_email_address: Yup.string()
          .trim()
          .email("Must be a valid email address"),
        customer_contact_number: Yup.string()
          .min(10, "Too short")
          .max(20, "Too long"),
      })
    ),
    fine_amount: Yup.number().when("isPenaltyLock", {
      is: false,
      then: Yup.number()
        .required("Penalty amount is required")
        // .positive("Penalty amount must be greater than zero")
        .typeError("Penalty amount must be a number"),
    }),
    note_penalty: Yup.string().when("isPenaltyLock", {
      is: false,
      then: Yup.string().trim().required("Penalty note must be required."),
    }),
  });

  const initialValues = {
    customer_first_name: orderDetails?.customer_first_name || "",
    customer_last_name: orderDetails?.customer_last_name || "",
    customer_email: orderDetails?.customer_email || "",
    customer_contact_number: orderDetails?.customer_contact_number || "",
    customer_shipping_address_line_1:
      orderDetails?.customer_shipping_address_line_1 || "",
    customer_shipping_address_line_2:
      orderDetails?.customer_shipping_address_line_2 || "",
    customer_shipping_country: orderDetails?.customer_shipping_country || "",
    customer_shipping_postcode: orderDetails?.customer_shipping_postcode || "",
    customer_shipping_town: orderDetails?.customer_shipping_town || "",
    customer_billing_address_line_1:
      orderDetails?.customer_billing_address_line_1 || "",
    customer_billing_address_line_2:
      orderDetails?.customer_billing_address_line_2 || "",
    customer_billing_country: orderDetails?.customer_billing_country || "",
    customer_billing_postcode: orderDetails?.customer_billing_postcode || "",
    customer_billing_town: orderDetails?.customer_billing_town || "",
    customer_nationality: orderDetails?.customer_nationality || "",
    customer_city_of_birth: orderDetails?.customer_city_of_birth || "",
    items:
      orderDetails?.items?.map((obj: any) => ({
        id: obj?.id,
        name: obj?.name,
        row: obj?.row,
        seat: obj?.seat,
        customer_email_address: obj?.customer_email_address,
        customer_national_id: obj?.customer_national_id,
        customer_nationality: obj?.customer_nationality,
        customer_contact_number: obj.customer_contact_number,
        customer_birth_place: obj?.customer_birth_place,
        customer_address: obj?.customer_address,
        customer_dob: obj?.customer_dob ? new Date(obj?.customer_dob) : null,
      })) || [],
    order_status_id: orderDetails?.internal_order_status,
    order_status: orderDetails?.order_status,
    note_penalty: orderDetails?.note_penalty || "",
    fine_amount: orderDetails?.fine_amount || "",
    isPenaltyLock: isPenaltyLock,
  };

  // FORMIk
  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: detailsSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values: any) => {
      setUpdateLoader(true);
      await updateCustomerDetails({
        ...formik.values,
        order_id: orderDetails?.order_id,
        items: values?.items?.map((item: any) => {
          return {
            ...item,
            customer_dob: item?.customer_dob
              ? moment(item?.customer_dob).format("YYYY-MM-DD")
              : item?.customer_dob,
          };
        }),
      })
        .then((response: any) => {
          setUpdateLoader(false);
          if (response) {
            if (response?.data.status) {
              showAlert(response?.message, false);
              setOrderDetails((pre: any) => ({
                ...pre,
                ...response?.data?.payload.customer,
                items: response?.data?.payload.items,
                internal_order_status: formik?.values?.order_status_id,
              }));
              refetch();
            } else {
              if (response?.errors?.title) {
                showAlert(response?.errors?.title, true);
              } else {
                showAlert("Something went wrong.", true);
              }
            }

            layout?.setOrders((pre: any) => {
              return pre?.map((obj: any) => {
                if (obj?.order_id === orderDetails?.order_id) {
                  return {
                    ...obj,
                    internal_order_status: formik?.values?.order_status_id,
                  };
                } else {
                  return obj;
                }
              });
            });

            // setShowOrder((prev: any) => ({
            //   index: prev?.index,
            //   show: prev?.show,
            //   data: {
            //     ...prev?.data,
            //     ...response?.data?.payload.customer,
            //     items: response?.data?.payload.items,
            //   },
            // }));
            setIsCustomerLock(true);
            setIsAddressLock(true);
            setIsTicketLock(true);
            setIsStatusLock(true);
          }
        })
        .catch((error) => {
          setUpdateLoader(false);
          console.error(error);
        });
    },
  });

  const MarketplaceVisible =
    orderDetails?.is_legacy_order == "true" || isGodAdmin;

  const addressCheck =
    orderDetails?.customer_billing_address_line_1 ||
    orderDetails?.customer_shipping_address_line_1;

  let deliveryByDays = daysDifference(
    parseMMDDYYYY(orderDetails?.deliver_by),
    new Date()
  );
  deliveryByDays = isNaN(deliveryByDays) ? 0 : deliveryByDays;

  let { statusColor, dateColor } = getOrderStatusColor({
    ...orderDetails,
    deliveryByDays,
  });

  useEffect(() => {
    formik.setFieldValue(`isPenaltyLock`, isPenaltyLock);
  }, [isPenaltyLock]);

  // HANDLE ORDER ASSING LOCK
  const handleOrderLock = (id: any) => {
    setIsadministrationLock((current: any) => {
      if (!current?.status) {
        if (
          orderDetails?.internal_order_status !==
          formik?.values?.order_status_id
        ) {
          formik.setFieldValue(
            `order_status_id`,
            orderDetails?.internal_order_status
          );
        }
      }
      if (!current?.id || current?.id !== id) {
        let data = {
          order_id: id,
        };
        suggestListingReplacement(data).then((response: any) => {
          if (response?.data?.categories) {
            layout.setOrderNewFilterHandle((current: any) => {
              let category_id = [
                response?.data?.categories?.find(
                  (item: any) => item?.name === orderDetails?.category
                ),
              ].filter(Boolean);
              let ticket_type = [
                layout?.salesOptions?.ticket_type?.find(
                  (item: any) =>
                    item?.name ===
                    ticketTypeRenameForSalesReport(
                      orderDetails?.ticket_type,
                      orderDetails?.sub_ticket_type
                    )
                ),
              ].filter(Boolean);
              setExtrasSuggestListing({
                category_id,
                ticket_type,
                id,
              });
              return {
                ...current,
                category_id: category_id,
                ticket_type: ticket_type,
              };
            });
          }
        });
      }
      return {
        id: id,
        status: !current?.status,
      };
    });
  };

  let penaltyDate =
    _.size(orderNotes) > 0 &&
    orderNotes?.find((item: any) => item?.type === 2)?.date;

  //SET BENIFITS AND RESTRICTION
  let benefitsRestrictions = orderDetails?.restrictions;

  const hasLockedOrder = layout.orders?.filter(
    (order: any) => order?.isLock === true
  );

  const unLockedOrders = layout.orders?.filter((order: any) => !order?.isLock);
  // let pngImagePath = "/media/png/";

  return (
    <>
      {layout.fullShow && (
        <FullOrderDetail
          // show={show}
          show={{ data: { ...orderDetails, history: orderNotes } }}
          tabValue={tabValue}
          setShowOrder={setShowOrder}
          closeFullOrderDetail={() => {
            display(false);
            handleShow({
              show: false,
              data: "",
            });
            layout.setFullShow(false);
          }}
          handleOrder={(data: any) => handleOrder(show?.data)}
          isGodAdmin={isGodAdmin}
          handleShow={handleShow}
          setOrderDetails={setOrderDetails}
          optionsData={optionsData}
          handleMizimize={(data: any) => {
            setOrderNotes(data?.orderNotes);
          }}
          refetch={refetch}
          detailsListingData={detailsListingData}
          teamsOptions={teamsOptions}
        />
      )}

      <form
        onSubmit={(e: any) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div
          data-te-modal-init
          className={`${
            showOrder?.show ? "hidden" : "show"
          } popupinput-style2 upload-ticket-popup offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-1000 ease-in-out font-medium z-40 md:max-w-[43.125rem] w-full`}
          tabIndex={-1}
          id="singleTicket"
          aria-labelledby="singleTicketLabel"
        >
          <div className="  bg-white border-t w-full h-full ml-auto flex flex-col">
            <div className="flex justify-between pl-5 items-center border-b">
              <div>
                <span className="md:text-sm15 text-sm12 block font-semibold">
                  {" "}
                  Order ID: {orderDetails?.order_id}
                </span>
              </div>
              <div className="flex flex-wrap">
                {/* E-TICKET - BUTTON */}
                {tabValue === "to_be_fulfilled" && legacyCheck !== true && (
                  <>
                    {orderDetails?.is_txtrade_pending_order ? (
                      <button
                        className={`group relative flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l 
                      
                      `}
                        type="button"
                        aria-label="Close"
                        id="infoerroricon"
                      >
                        <IKTSVG
                          path={WarningIcon}
                          className="fill-rose-500"
                          svgClassName="w-4 h-4"
                        />
                        <Tooltip
                          anchorId={`infoerroricon`}
                          content={`Attendee details pending`}
                          place={"left"}
                          variant="light"
                          className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
                        />
                      </button>
                    ) : (
                      <button
                        className={`${
                          isDetailsLoading && "shimmer-effect"
                        }  group relative flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l ${
                          orderDetails?.is_legacy_order == "true"
                            ? `pointer-events-none bg-gray-100 hidden`
                            : ""
                        } 
                      
                      `}
                        type="button"
                        aria-label="Close"
                        id={"upload-ticekt"}
                        onClick={(e: any) => {
                          display(false);
                          handleShow({
                            show: false,
                            data: "",
                          });
                          handleOrder(show?.data);
                        }}
                      >
                        <IKTSVG
                          className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition `}
                          path={UploadSVGNew}
                          svgClassName="w-3.5 h-3"
                        />
                        <Tooltip
                          anchorId={`upload-ticekt`}
                          content={`${
                            orderDetails?.ticket_type === "mobile"
                              ? "Confirm transfer"
                              : orderDetails?.ticket_type === "eticket" ||
                                orderDetails?.ticket_type === "PDF"
                              ? orderDetails?.ticket_quantity > 1
                                ? "Fulfill tickets"
                                : "Fulfill ticket"
                              : orderDetails?.ticket_type === "mobile-link"
                              ? "Confirm mobile link"
                              : orderDetails?.ticket_type === "paper" ||
                                orderDetails?.ticket_type === "season-cards"
                              ? "Confirm shipping"
                              : "Upload ticket"
                          }`}
                          place={"left"}
                          variant="light"
                          className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
                        />
                      </button>
                    )}
                  </>
                )}

                {/* FULL ORDER DETAIL - BUTTON */}
                <button
                  className="group relative max-md:hidden flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l"
                  type="button"
                  id="maximize"
                  onClick={(e: any) => {
                    layout.setFullShow(true);
                    // setTimeout(() => {
                    //   display(false);
                    // }, 500);
                  }}
                >
                  <IKTSVG
                    className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition `}
                    path={fullSVG}
                    svgClassName="w-3 h-3"
                  />
                  <Tooltip
                    anchorId={`maximize`}
                    content={`Maximize order`}
                    place={"left"}
                    variant="light"
                    className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
                  />
                </button>

                {/* CLOSE - BUTTON */}
                <button
                  type="button"
                  className="group relative mail flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l"
                  data-bs-dismiss="offcanvas"
                  ref={canvasRef}
                  aria-label="Close"
                  id="close"
                  onClick={() => {
                    handleShow({
                      show: false,
                      data: "",
                    });
                    display(false);
                  }}
                >
                  <IKTSVG
                    className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition"
                    path={crossArrow}
                    svgClassName="w-2.5 h-2.5"
                  />

                  <Tooltip
                    anchorId={`close`}
                    content={`Close`}
                    place={"left"}
                    variant="light"
                    className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
                  />
                </button>
              </div>
            </div>

            {/* VIEW ORDER DETAIL */}
            {isDetailsLoading ? (
              <PopUpShimmer />
            ) : (
              <>
                {" "}
                <div className="p-5 h-full overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                  <div className="">
                    {isGodAdmin && (
                      <div
                        className={`rounded mb-5 last-of-type:mb-0 relative border ${
                          isadministrationLock?.status
                            ? ""
                            : "shadow-op2 border-transparent"
                        }`}
                      >
                        <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                          <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                            Order administration (internal)
                          </h5>
                          {isGodAdmin && (
                            <button
                              type="button"
                              className="w-10 flex items-center justify-center group border-l"
                              onClick={() => {
                                setLedgerNote("");
                                setLedgerNoteFocused(false);
                                handleOrderLock(orderDetails?.order_id);
                                setCancelOrder({
                                  cancelOrderBtn: false,
                                  priceLock: false,
                                });
                                setRefundAmount((prev: any) => ({
                                  ...prev,
                                  amount: orderDetails?.refund_amount
                                    ? decimalNumber(orderDetails?.refund_amount)
                                    : orderDetails?.refund_amount,
                                }));
                                // setRefundAmount(Number(orderDetails?.refund_amount));
                              }}
                            >
                              <IKTSVG
                                // path={LockIcon}
                                path={
                                  isadministrationLock?.status
                                    ? LockIcon
                                    : LockIconOpen
                                }
                                svgClassName={`group-hover:fill-indigo-500   h-[.9375rem] ${
                                  isadministrationLock?.status
                                    ? "w-3 fill-violet-500"
                                    : "w-[.8438rem] ml-[.1875rem] fill-indigo-500"
                                }`}
                              />
                            </button>
                          )}
                        </div>
                        <div className="block_content p-5">
                          <div className="block_row flex flex-wrap -mx-2.5 mt-[.9375rem] first-of-type:mt-0 max-md:gap-y-5">
                            <div className="s_field md:w-1/2 w-full px-2.5 text-sm12">
                              <label className="text-gray-400 leading-4 mb-1 block">
                                Internal order status
                              </label>
                              <div className="inner_s_field mb-4">
                                {isadministrationLock?.status ? (
                                  <div className="label-text px-2 py-[.1875rem] bg-gray-100 rounded">
                                    {formik?.values?.order_status_id
                                      ? formik?.values?.order_status_id
                                      : "-"}
                                  </div>
                                ) : (
                                  <>
                                    {/* Dorpdown */}
                                    <div className="full-dropdown text-sm12 border-violet-900 flex flex-wrap items-center relative">
                                      <TableDropdown
                                        options={
                                          optionsData?.data
                                            ?.internal_order_statuses
                                        }
                                        name="ticket-type"
                                        placeholder="Select"
                                        value={optionsData?.data?.internal_order_statuses?.find(
                                          (option: any) =>
                                            option?.name ===
                                            formik?.values?.order_status_id
                                        )}
                                        isClearable={false}
                                        handleOnChange={(e: any) => {
                                          formik.setFieldValue(
                                            `order_status_id`,
                                            e.target.value?.id
                                          );
                                          formik.setFieldValue(
                                            `order_status`,
                                            e.target.value?.description
                                          );
                                        }}
                                        isValueCapital={true}
                                        isCapital={true}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              {!isadministrationLock?.status &&
                                cancelOrder?.cancelOrderBtn && (
                                  <div className="text-xs text-left border rounded overflow-hidden">
                                    {refundBreakdown &&
                                      refundBreakdown?.length > 0 &&
                                      refundBreakdown?.map((breakdown: any) => {
                                        return (
                                          <div className="whitespace-nowrap md:w-auto font-medium min-w-[8.125rem] -m-px">
                                            <div className="w-full flex border -mt-px">
                                              <div className="w-1/2 whitespace-nowrap overflow-hidden text-ellipsis text-gray-400  border-r p-1">
                                                {breakdown?.label} :
                                              </div>
                                              <div className="w-1/2 p-1 whitespace-nowrap overflow-hidden text-ellipsis">
                                                {`${
                                                  breakdown.is_amount
                                                    ? SYMBOLS[
                                                        orderDetails
                                                          ?.refund_currency
                                                      ]
                                                    : ""
                                                }${
                                                  breakdown.is_amount
                                                    ? decimalNumber(
                                                        breakdown?.value
                                                      )
                                                    : breakdown?.value
                                                }`}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                            </div>

                            {orderDetails?.cloned_order_id && (
                              <CopyToClipboard
                                headerName="Assigned to order ID"
                                padding="max-md:px-2.5"
                                margin="mb-[.125rem]"
                                value={`${orderDetails?.cloned_order_id}`}
                                id={"cloned_order_id"}
                                handleClick={(data: any) => {
                                  let copiedValues = copy(data?.value);
                                  setIsCopied({
                                    id: data?.id,
                                    value: copiedValues,
                                  });
                                }}
                              />
                              // <div className="s_field w-1/2 px-2.5 text-sm12">
                              //   <label className="text-gray-400 leading-4 mb-1 block">
                              //     Assigned to order ID
                              //   </label>
                              //   <div className="inner_s_field ">
                              //     <div className="label-text px-2 py-1 bg-gray-100 rounded relative flex justify-between">
                              //       {orderDetails?.cloned_order_id}
                              //       <IKTSVG
                              //         className={`flex items-center justify-center transition fill-violet-500 hover:fill-indigo-500`}
                              //         path={CloneSVG}
                              //         svgClassName="w-[.7813rem] h-[.6563rem]"
                              //       />
                              //     </div>
                              //   </div>
                              // </div>
                            )}
                            {showCancelOrderButton && (
                              <div className="s_field w-full md:w-1/2 px-2.5 md:mt-5 text-sm12 flex items-start flex-wrap">
                                <button
                                  type="button"
                                  className={`${
                                    isadministrationLock?.status
                                      ? "bg-gray-100 hover:bg-gray-100 text-gray-400 pointer-events-none"
                                      : "bg-violet-500 hover:bg-indigo-500 text-white"
                                  }
                                    group px-2.5 py-0.5 min-h-[1.5rem] rounded flex items-center text-sm13  transition-all`}
                                  onClick={(e: any) => {
                                    setCancelOrder((prev: any) => ({
                                      ...prev,
                                      cancelOrderBtn: true,
                                    }));
                                  }}
                                >
                                  Cancel order
                                </button>
                                {!isadministrationLock?.status &&
                                  cancelOrder?.cancelOrderBtn && (
                                    <>
                                      <div
                                        className={`relative w-full flex rounded text-sm12 py-2.5 mt-2 ${
                                          !cancelOrder.priceLock
                                            ? `refund-amount-wrapper`
                                            : ``
                                        }`}
                                      >
                                        <PriceField
                                          id="refund_amount"
                                          name="refund_amount"
                                          type="text"
                                          value={refundAmount?.amount}
                                          label="Refund Amount"
                                          required={false}
                                          currenncySymbol={
                                            orderDetails.refund_currency
                                          }
                                          isDisabled={
                                            cancelOrder.priceLock ? false : true
                                          }
                                          handleOnChange={(data: any) => {
                                            setRefundAmount((prev: any) => ({
                                              ...prev,
                                              amount:
                                                data?.target?.value?.value,
                                            }));
                                          }}
                                          handleOnBlur={(e: any) => {
                                            let value = e?.target?.value?.value;
                                            if (!isValidRefundAmount(value)) {
                                              let formattedValue = String(
                                                Number(value).toFixed(2)
                                              );

                                              setRefundAmount((prev: any) => ({
                                                ...prev,
                                                amount:
                                                  formattedValue === "NaN"
                                                    ? prev?.amount
                                                    : formattedValue,
                                              }));
                                            }
                                          }}
                                          errorClass={
                                            error && "border-rose-500"
                                          }
                                          labelErrorClass={
                                            error && "text-rose-500"
                                          }
                                          errorEventIndex={"refund_amount"}
                                          errorMessage={error}
                                          withLockBtnClass={"w-full"}
                                        />
                                        <button
                                          type="button"
                                          className="w-8 flex items-center justify-center group ml-2 border border-gray-300 rounded"
                                          onClick={() => {
                                            // only format refund  amount when two point is not present

                                            if (
                                              isValidPriceFormat(
                                                refundAmount?.amount
                                              )
                                            )
                                              setRefundAmount((prev: any) => ({
                                                ...prev,
                                                amount: decimalNumber(
                                                  removeCommaFromNumber(
                                                    refundAmount?.amount
                                                  )
                                                ),
                                              }));
                                            setCancelOrder((prev) => ({
                                              cancelOrderBtn: true,
                                              priceLock: !prev.priceLock,
                                            }));
                                          }}
                                        >
                                          <IKTSVG
                                            path={
                                              cancelOrder?.priceLock
                                                ? LockIconOpen
                                                : LockIcon
                                            }
                                            svgClassName={`group-hover:fill-indigo-500  h-[.9375rem] ${
                                              cancelOrder?.priceLock
                                                ? "w-3 fill-violet-500"
                                                : "w-[.8438rem] fill-indigo-500"
                                            }`}
                                          />
                                        </button>
                                      </div>
                                      <div className="w-full flex border rounded -mt-px font-medium mb-2.5">
                                        <div
                                          className="flex-auto border-r p-1"
                                          title="Ticket type"
                                        >
                                          TX trade wallet balance:
                                        </div>
                                        <div className="flex-auto p-1">
                                          {`${
                                            SYMBOLS[
                                              orderDetails?.refund_currency
                                            ]
                                          }${decimalNumber(availableBalance)}`}
                                        </div>
                                      </div>
                                      {paymentMethod && (
                                        <div className="w-full flex border rounded -mt-px font-medium mb-4">
                                          <TextInput
                                            name="ledgerNote"
                                            type="text"
                                            value={ledgerNote}
                                            required={false}
                                            placeholder={
                                              Messages?.ledgerNotePlaceholder
                                            }
                                            className={`!h-6 bg-gray-100/50`}
                                            handleOnChange={(e: any) => {
                                              setLedgerNoteFocused(true);
                                              setLedgerNote(e?.target?.value);
                                            }}
                                            handleClearValue={() =>
                                              setLedgerNote("")
                                            }
                                            errorClass={
                                              paymentMethod &&
                                              ledgerNote?.trim() === "" &&
                                              ledgerNoteFocused &&
                                              "!border-rose-500"
                                            }
                                          />
                                        </div>
                                      )}
                                      <div className="flex items-center">
                                        <span className="mr-4">
                                          Are you sure you want to cancel the
                                          order and refund to the buyer?
                                        </span>
                                        <div className="flex">
                                          {/* CANCEL */}
                                          <button
                                            type="button"
                                            data-tooltip-id="cancel-order-btn"
                                            className="bg-white hover:bg-indigo-500 border border-gray-300 hover:border-indigo-500 group flex items-center justify-center h-6 w-6 text-white transition rounded mr-2"
                                            onClick={() => {
                                              setLedgerNoteFocused(false);
                                              setCancelOrder({
                                                cancelOrderBtn: false,
                                                priceLock: false,
                                              });
                                            }}
                                          >
                                            <IKTSVG
                                              className="fill-violet-500 group-hover:fill-white transition"
                                              path={CrossSVG}
                                              svgClassName="w-[.625rem] h-[.625rem]"
                                            />
                                            <Tooltip
                                              id="cancel-order-btn"
                                              content="Cancel"
                                              place="top"
                                              variant="light"
                                              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                            />
                                          </button>

                                          {/* SAVE */}
                                          <button
                                            type="button"
                                            // disabled={error?.errorMessage !== ""}
                                            data-tooltip-id="save-order-btn"
                                            onClick={() => {
                                              setLedgerNoteFocused(true);
                                              handleCancelOrder(
                                                orderDetails?.order_id,
                                                refundAmount
                                              );
                                            }}
                                            className="bg-violet-500 hover:bg-indigo-500 border border-violet-500 hover:border-indigo-500 group flex items-center justify-center h-6 w-6 text-white transition rounded"
                                          >
                                            <IKTSVG
                                              className="fill-white group-hover:fill-white transition"
                                              path={CheckSVG}
                                              svgClassName="w-[.75rem] h-[.625rem]"
                                            />
                                            <Tooltip
                                              id="save-order-btn"
                                              content="Confirm"
                                              place="top"
                                              variant="light"
                                              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )}
                              </div>
                            )}
                          </div>
                          {!orderDetails?.cloned_order_id &&
                            isGodAdmin &&
                            new Date(orderDetails?.event_date) >= new Date() &&
                            (tabValue === "to_be_fulfilled" ||
                              tabValue === "new") && (
                              <div className="block_row flex  flex-wrap -mx-2.5 mt-[.9375rem] first-of-type:mt-0">
                                <label className="text-gray-400 leading-4 mb-1 block w-full px-2.5 text-sm12">
                                  Order replacement options
                                </label>
                                <div className="s_field w-1/2 px-2.5 text-sm12">
                                  <div className="inner_s_field  ">
                                    <button
                                      type="button"
                                      className={`${
                                        isadministrationLock?.status
                                          ? "bg-gray-100 hover:bg-gray-100 text-gray-400 pointer-events-none"
                                          : "bg-violet-500 hover:bg-indigo-500 text-white"
                                      }
                                    group px-2.5 py-0.5 min-h-[1.5rem] rounded flex items-center text-sm13  transition-all w-full`}
                                      onClick={async () => {
                                        layout.setOrderAssign(
                                          (current: any) => {
                                            return {
                                              ...current,
                                              status: true,
                                              data: {
                                                ...orderDetails,
                                                internalOrderOptions:
                                                  optionsData,
                                              },
                                            };
                                          }
                                        );

                                        layout?.setOrderAssignNewTeam(
                                          ORDER_ASSIGN_TO_NEW_TEAM
                                        );

                                        if (extrasSuggestListing) {
                                          layout.setOrderNewFilterHandle(
                                            (current: any) => {
                                              return {
                                                ...current,
                                                ...extrasSuggestListing,
                                              };
                                            }
                                          );
                                        }
                                      }}
                                    >
                                      <IKTSVG
                                        path={
                                          isadministrationLock?.status
                                            ? assignGray
                                            : assignIcon
                                        }
                                        svgClassName={`w-3.5 h-[.8125rem] `}
                                        className="mr-[.4375rem] fill-gray-400"
                                      />
                                      Assign order to new listing
                                    </button>
                                  </div>
                                </div>
                                <div className="s_field w-1/2 px-2.5 text-sm12">
                                  <div className="inner_s_field  ">
                                    <button
                                      type="button"
                                      className={`
                                    
                                    ${
                                      isadministrationLock?.status
                                        ? "bg-gray-100 hover:bg-gray-100 text-gray-400 pointer-events-none"
                                        : "bg-violet-500 hover:bg-indigo-500 text-white"
                                    }
                                    group px-2.5 py-0.5 min-h-[1.5rem] rounded flex items-center text-sm13  transition-all w-full`}
                                      onClick={() => {
                                        layout.setOrderAssign(
                                          ORDER_ASSIGN_TO_NEW_LISTING
                                        );
                                        layout.setOrderAssignNewTeam({
                                          status: true,
                                          data: {
                                            ...orderDetails,
                                            internalOrderOptions: {
                                              ...optionsData,
                                              teamsOptions: teamsOptions,
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      <IKTSVG
                                        path={
                                          isadministrationLock?.status
                                            ? assignGray
                                            : assignIcon
                                        }
                                        svgClassName="w-3.5 h-[.8125rem]"
                                        className="mr-[.4375rem]"
                                      />
                                      Assign order to new team
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    )}

                    <div className=" flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0">
                      {/* <div className="title text-sm13 absolute font-semibold bg-white px-[.3125rem] left-[.9375rem] top-0 -translate-y-[50%] leading-4">
                          Order Note
                        </div> */}
                      <div
                        className={`title_block w-full ${
                          orderNotes?.length > 0 || orderText?.show
                            ? "border-b"
                            : ""
                        } flex  justify-between min-h-[2.5rem]`}
                      >
                        <div className="left_block flex items-center justify-between flex-1 pr-5">
                          <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                            Order notes
                          </h5>
                          <button
                            type="button"
                            className={`group inline-flex group items-center    transition font-medium rounded text-sm13 px-2.5 py-0.5 false ${
                              orderText?.show
                                ? "bg-gray-100 hover:bg-gray-100 text-gray-400 pointer-events-none"
                                : "bg-violet-500 hover:bg-indigo-500 text-white"
                            }`}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#detailCanvas"
                            aria-controls="detailCanvas"
                            onClick={HadleAddOrderNote}
                          >
                            <IKTSVG
                              className={`min-w-[0.75rem] max-w-[0.75rem] mr-1 flex items-center justify-center  ${
                                orderText?.show
                                  ? "fill-gray-400 group-hover:fill-gray-400"
                                  : "fill-violet-500 group-hover:fill-white"
                              }`}
                              path={orderText?.show ? AddSVGGray : AddSVG}
                              svgClassName={`fill-violet-500`}
                            />
                            Add note
                          </button>
                        </div>
                        {/* {isGodAdmin && (
                            <button
                              type="button"
                              className="w-10 flex items-center justify-center group border-l"
                              onClick={() => setIsNoteLock(!isNoteLock)}
                            >
                              <IKTSVG
                                // path={LockIcon}
                                path={isNoteLock ? LockIcon : LockIconOpen}
                                svgClassName={`group-hover:fill-indigo-500  h-[.9375rem] ${
                                  isNoteLock
                                    ? "w-3 fill-violet-500"
                                    : "w-[.9375rem] ml-[.1875rem] fill-indigo-500"
                                }`}
                              />
                            </button>
                          )} */}
                      </div>
                      {(orderNotes?.length > 0 || orderText?.show) && (
                        <div className="py-5 px-5 justify-start w-full">
                          {orderText?.show && (
                            <div
                              className={`add-note-btn  ${
                                noteLoader ? `shimmer-effect` : ``
                              }`}
                            >
                              <div className="m-auto">
                                {isGodAdmin && (
                                  <div
                                    className={`flex items-center gap-2 font-medium pb-2.5 ${
                                      orderText?.confirmed
                                        ? "pointer-events-none"
                                        : ""
                                    }`}
                                  >
                                    <div className="inline-flex items-center font-medium rounded text-sm13 single_radio leading-5">
                                      <input
                                        type="radio"
                                        name="orderType"
                                        value="internal"
                                        checked={
                                          orderText?.orderType === "internal"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          orderText?.confirmed ? true : false
                                        }
                                        id="orderNoteInternal"
                                        onChange={(e: any) => {
                                          setOrderText((prev: any) => {
                                            return {
                                              ...prev,
                                              orderType: "internal",
                                            };
                                          });
                                        }}
                                      />
                                      <label
                                        htmlFor="orderNoteInternal"
                                        className="ml-1"
                                      >
                                        Internal
                                      </label>
                                    </div>
                                    <div className="inline-flex items-center font-medium rounded text-sm13 single_radio leading-5">
                                      <input
                                        type="radio"
                                        name="orderType"
                                        value="external"
                                        checked={
                                          orderText?.orderType === "external"
                                            ? true
                                            : false
                                        }
                                        id="orderNoteExternal"
                                        disabled={
                                          orderText?.confirmed ? true : false
                                        }
                                        onChange={(e: any) => {
                                          setOrderText((prev: any) => {
                                            return {
                                              ...prev,
                                              orderType: "external",
                                            };
                                          });
                                        }}
                                      />
                                      <label
                                        htmlFor="orderNoteExternal"
                                        className="ml-1"
                                      >
                                        External
                                      </label>
                                    </div>
                                  </div>
                                )}
                                <input
                                  className={` font-medium transition bg-gray-100 focus:border-indigo-500 focus:bg-white outline-none peer
                                  border border-gray-100 whitespace-normal text-sm12 rounded w-full px-2 block text-violet-800 resize-none py-1 leading-4`}
                                  value={orderText?.note}
                                  name="note"
                                  disabled={orderText?.confirmed ? true : false}
                                  onChange={(e: any) => handleOrderText(e)}
                                  placeholder={Messages?.orderNotePlaceholder}
                                />

                                {orderText?.confirmed ? (
                                  <div className="flex flex-wrap mt-2.5 gap-x-2">
                                    <div className="conf_text flex flex-wrap w-full items-center gap-2.5">
                                      <p className="text-sm12 ">
                                        Are you sure you want to add this
                                        {orderText?.orderType === "internal"
                                          ? " INTERNAL"
                                          : " EXTERNAL"}{" "}
                                        note?
                                      </p>
                                      <button
                                        type="button"
                                        className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                                        onClick={cancleOrderText}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="button"
                                        className="border border-violet-500 hover:border-indigo-500 text-violet-800 text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                                        onClick={(e: any) => addOrderText(e)}
                                      >
                                        Confirm
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div className="flex flex-wrap mt-2.5 gap-x-2">
                                      {/* RESET */}
                                      <button
                                        type="button"
                                        className="group flex items-center justify-center h-6 w-6 border border-gray-300 hover:border-violet-500 text-white bg-white hover:bg-violet-500 transition rounded"
                                        data-tooltip-id={`reset-order-note`}
                                        onClick={() => {
                                          setOrderText((prev: any) => {
                                            return {
                                              show: true,
                                              note: "",
                                              orderType: "internal",
                                            };
                                          });
                                        }}
                                      >
                                        <IKTSVG
                                          className="fill-violet-500 group-hover:fill-white transition"
                                          path={ReloadIconSVG}
                                          svgClassName="w-[.75rem] h-[.75rem]"
                                        />
                                        <Tooltip
                                          id={`reset-order-note`}
                                          content={`Reset`}
                                          place="top"
                                          variant="light"
                                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 "
                                        />
                                      </button>

                                      {/* CANCEL */}
                                      <button
                                        type="button"
                                        data-tooltip-id={`cancel-order-note`}
                                        className="group flex items-center justify-center h-6 w-6 border border-gray-300 hover:border-violet-500 text-white bg-white hover:bg-violet-500 transition rounded"
                                        onClick={cancleOrderText}
                                      >
                                        <IKTSVG
                                          className="fill-violet-500 group-hover:fill-white transition"
                                          path={CrossSVG}
                                          svgClassName="w-[.625rem] h-[.625rem]"
                                        />
                                        <Tooltip
                                          id={`cancel-order-note`}
                                          content={`Cancel`}
                                          place="top"
                                          variant="light"
                                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 "
                                        />
                                      </button>

                                      {/* SAVE */}
                                      <button
                                        type="button"
                                        data-tooltip-id={`save-order-note`}
                                        disabled={
                                          orderText?.note ? false : true
                                        }
                                        onClick={(e: any) => {
                                          setOrderText((prev: any) => {
                                            return {
                                              ...prev,
                                              confirmed: isGodAdmin
                                                ? true
                                                : false,
                                            };
                                          });

                                          !isGodAdmin && addOrderText(e);
                                        }}
                                        className={`${
                                          !orderText?.note
                                            ? "pointer-events-none bg-gray-100"
                                            : ""
                                        } group flex items-center justify-center h-6 w-6 border  border-gray-300 hover:border-violet-500 text-white bg-white hover:bg-violet-500 transition rounded`}
                                      >
                                        <IKTSVG
                                          className="fill-violet-500 group-hover:fill-white transition"
                                          path={CheckSVG}
                                          svgClassName="w-[.75rem] h-[.625rem]"
                                        />
                                        <Tooltip
                                          id={`save-order-note`}
                                          content={`${
                                            isGodAdmin ? "Save" : "Confirm"
                                          }`}
                                          place="top"
                                          variant="light"
                                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 "
                                        />
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          )}

                          {/* ORDER NOTES */}
                          <div
                            className={`note-block ${
                              orderText?.show && "mt-5"
                            }`}
                          >
                            {orderNotes?.map((note: any) => {
                              if (isGodAdmin) {
                                return (
                                  <div className={`mt-5 first-of-type:mt-0 `}>
                                    <span
                                      className={`text-sm12 font-medium mb-1 block text-gray-400 ${
                                        noteLoader ? `shimmer-effect` : ``
                                      }`}
                                    >
                                      {note?.type === 1
                                        ? "Internal"
                                        : note?.type === 2
                                        ? "Penalty"
                                        : note?.type === 3
                                        ? "External"
                                        : note?.type === 4 && note?.user_name}
                                      {" - "}
                                      {moment(note?.date).format(
                                        "DD/MM/YYYY HH:mm"
                                      )}{" "}
                                    </span>
                                    <div
                                      className={`px-2 py-1 bg-gray-100 flex justify-between rounded whitespace-pre-line ${
                                        noteLoader ? `shimmer-effect` : ``
                                      }`}
                                    >
                                      {/* {note?.type} */}
                                      <p className="text-sm12 break-words overflow-hidden">
                                        {note?.note}
                                      </p>
                                      {note?.type !== 2 && (
                                        <span
                                          id={`note-${note?.index}`}
                                          data-tooltip-id={`note-${note?.index}`}
                                          className="mt-1 mr-0.5 cursor-pointer  transition right-0 top-0"
                                          onClick={(e: any) =>
                                            deleteOrderText({
                                              index: note?.index,
                                            })
                                          }
                                        >
                                          <TooltipPortal
                                            id={`note-${note?.index}`}
                                            content="Delete"
                                            className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                                          />
                                          <IKTSVG
                                            className={`fill-violet-500 hover:fill-indigo-500 transition`}
                                            svgClassName="w-[.5rem] h-[.5rem]"
                                            path={CrossSVG}
                                          />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                );
                              } else if (note?.type !== 1) {
                                return (
                                  <div className={`mt-5 first-of-type:mt-0 `}>
                                    <span
                                      className={`text-sm12 font-medium mb-1 block text-gray-400 ${
                                        noteLoader ? `shimmer-effect` : ``
                                      }`}
                                    >
                                      {note?.type === 2
                                        ? "Penalty"
                                        : note?.type === 3
                                        ? "External"
                                        : note?.type === 4 && note?.user_name}
                                      {" - "}
                                      {moment(note?.date).format(
                                        "DD/MM/YY HH:mm"
                                      )}{" "}
                                    </span>
                                    <div
                                      className={`px-2 py-1 bg-gray-100 flex justify-between rounded whitespace-pre-line ${
                                        noteLoader ? `shimmer-effect` : ``
                                      }`}
                                    >
                                      {/* {note?.type} */}
                                      <p className="text-sm12 break-words overflow-hidden">
                                        {note?.note}
                                      </p>
                                      {note?.type !== 2 && (
                                        <span
                                          id={`note-${note?.index}`}
                                          data-tooltip-id={`note-${note?.index}`}
                                          className="mt-1 mr-0.5 cursor-pointer  transition right-0 top-0"
                                          onClick={(e: any) =>
                                            deleteOrderText({
                                              index: note?.index,
                                            })
                                          }
                                        >
                                          <TooltipPortal
                                            id={`note-${note?.index}`}
                                            content="Delete"
                                            className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                                          />
                                          <IKTSVG
                                            className={`fill-violet-500 hover:fill-indigo-500 transition`}
                                            svgClassName="w-[.5rem] h-[.5rem]"
                                            path={CrossSVG}
                                          />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                          {/* {orderNotes?.length <= 0 && (
                            <div className="text-sm font-medium text-center my-4 p-2 bg-white rounded ">
                              No note available
                            </div>
                          )} */}
                        </div>
                      )}
                    </div>

                    {/* ORDER PENALTY */}
                    {(orderDetails?.fine_amount > 0 || isGodAdmin) && (
                      <div className="flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0 ">
                        <div
                          className={`title_block w-full border-b  flex  justify-between min-h-[2.5rem]`}
                        >
                          <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                            Order penalty
                          </h5>
                          {isGodAdmin &&
                            tabValue !== "completed" &&
                            !(orderDetails?.order_paid === 1) && (
                              <button
                                type="button"
                                className="w-10 flex items-center justify-center group border-l"
                                onClick={() => setIsPenaltyLock(!isPenaltyLock)}
                              >
                                <IKTSVG
                                  path={isPenaltyLock ? LockIcon : LockIconOpen}
                                  svgClassName={`group-hover:fill-indigo-500   h-[.9375rem] ${
                                    isPenaltyLock
                                      ? "w-3 fill-violet-500"
                                      : "w-[.8438rem] ml-[.1875rem] fill-indigo-500"
                                  }`}
                                />
                              </button>
                            )}
                        </div>

                        <div className="py-5 px-5 justify-start w-full">
                          <div className="note-block">
                            <div className="mt-5 first-of-type:mt-0 ">
                              <span
                                className={`text-sm12 font-medium block text-gray-400 ${
                                  isPenaltyLock ? "mb-1" : "mb-2"
                                }`}
                              >
                                Penalty{" "}
                                {penaltyDate &&
                                  `- ${moment(penaltyDate).format(
                                    "DD/MM/YYYY"
                                  )}`}{" "}
                              </span>
                              {isPenaltyLock ? (
                                <div className="px-2 py-1 bg-gray-100 flex justify-between rounded whitespace-pre-line ">
                                  <p className="text-sm12 break-words overflow-hidden">
                                    {orderDetails?.fine_amount
                                      ? `${
                                          SYMBOLS[orderDetails?.fine_currency]
                                        }${decimalNumber(
                                          orderDetails?.fine_amount
                                        )}`
                                      : "None"}
                                  </p>
                                </div>
                              ) : (
                                <div className="flex flex-col gap-2">
                                  <div className="flex-1 relative">
                                    <PriceField
                                      currenncySymbol={
                                        orderDetails?.fine_currency
                                      }
                                      name="fine_amount"
                                      type="text"
                                      value={formik?.values?.fine_amount}
                                      required={false}
                                      errorClass={
                                        formik.touched.fine_amount &&
                                        formik.errors.fine_amount &&
                                        "border-rose-500"
                                      }
                                      labelErrorClass={
                                        formik.touched.fine_amount &&
                                        formik.errors.fine_amount &&
                                        "text-rose-500 "
                                      }
                                      label="Penalty amount"
                                      id="amount"
                                      handleOnChange={(data: any) =>
                                        formik.setFieldValue(
                                          data?.target?.name,
                                          data?.target?.value?.value
                                        )
                                      }
                                      handleClearValue={() =>
                                        formik.setFieldValue("fine_amount", "")
                                      }
                                      errorEventIndex={"fine_amount"}
                                      errorMessage={formik.errors.fine_amount}
                                    />
                                    {formik.touched.fine_amount &&
                                      formik.errors.fine_amount && (
                                        <div className="text-xs text-rose-500 error-msg z-10">
                                          {formik.errors.fine_amount as string}
                                        </div>
                                      )}
                                  </div>
                                  <div className="flex-1 relative">
                                    <TextInput
                                      name={`note_penalty`}
                                      type="text"
                                      value={formik?.values?.note_penalty}
                                      required={false}
                                      placeholder={
                                        Messages?.penaltyNotePlaceholder
                                      }
                                      className="!h-6 bg-gray-100/50"
                                      handleClearValue={() =>
                                        formik.setFieldValue("note_penalty", "")
                                      }
                                      handleOnChange={formik.handleChange}
                                      errorClass={
                                        formik.touched.note_penalty &&
                                        formik.errors.note_penalty &&
                                        "!border-rose-500"
                                      }
                                    />
                                    {formik.touched.note_penalty &&
                                      formik.errors.note_penalty && (
                                        <div className="text-xs text-rose-500 error-msg ">
                                          {formik.errors.note_penalty as string}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0">
                      {/* <div className="title text-sm13 absolute font-semibold bg-white px-[.3125rem] left-[.9375rem] top-0 -translate-y-[50%] leading-4">
                          Order details
                        </div> */}
                      <div
                        className={`title_block w-full border-b  flex  justify-between min-h-[2.5rem]`}
                      >
                        <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                          Order details
                        </h5>
                        {/* {isGodAdmin && (
                            <button
                              type="button"
                              className="w-10 flex items-center justify-center group border-l"
                              onClick={() => setIsStatusLock(!isStatusLock)}
                            >
                              <IKTSVG
                                // path={LockIcon}
                                path={isStatusLock ? LockIcon : LockIconOpen}
                                svgClassName={`group-hover:fill-indigo-500  h-[.9375rem] ${
                                  isStatusLock
                                    ? "w-3 fill-violet-500"
                                    : "w-[.9375rem] ml-[.1875rem] fill-indigo-500"
                                }`}
                              />
                            </button>
                          )} */}
                      </div>

                      {/* <DetailInfo
                          header="Tixstock ID"
                          value={orderDetails?.order_id}
                        /> */}
                      <div className="inner-part px-3 py-3 flex flex-wrap">
                        <CopyToClipboard
                          headerName="Order ID"
                          value={`${orderDetails?.order_id}`}
                          id={"orderDetails"}
                          handleClick={(data: any) => {
                            let copiedValues = copy(data?.value);
                            setIsCopied({ id: data?.id, value: copiedValues });
                          }}
                        />
                        <DetailInfo
                          header="Order date"
                          value={
                            orderDetails?.order_date_time
                              ? moment(orderDetails?.order_date_time).format(
                                  "DD/MM/YYYY HH:mm:ss"
                                )
                              : null
                          }
                        />

                        {MarketplaceVisible && (
                          <DetailInfo
                            header="Marketplace"
                            value={orderDetails?.marketplace}
                          />
                        )}

                        {isGodAdmin && (
                          <DetailInfo
                            header="Marketplace ID"
                            value={orderDetails?.external_order_id}
                          />
                        )}

                        <div className={`md:w-1/2 w-full p-2`}>
                          <div
                            className={`text-gray-400 text-sm12 mb-1 ${
                              isGodAdmin &&
                              "flex flex-inline items-center justify-between"
                            }`}
                          >
                            <label>Order status</label>
                          </div>

                          {isEditable ? (
                            <div className="full-dropdown w-full text-sm12 border-violet-900 flex flex-wrap justify-between items-center relative">
                              <TableDropdown
                                options={ticketTypeOptions}
                                name="change_ticket_type"
                                placeholder="Ticket type"
                                value={ticketTypeValue}
                                isClearable={false}
                                handleOnChange={handleDropdownChange}
                                isValueCapital={true}
                                isCapital={true}
                              />

                              <div className="flex ml-2">
                                <button
                                  type="button"
                                  className={`bg-white hover:bg-indigo-500 border border-gray-300 hover:border-indigo-500 group flex mx-1 items-center justify-center h-6 w-6  text-white transition rounded mr-0`}
                                  id={`cancel-ticket-type`}
                                  onClick={() => {
                                    setTicketTypeValue(null);
                                    setButtonShow(false);
                                    setIsEditable(false);
                                  }}
                                >
                                  <Tooltip
                                    anchorId={`cancel-ticket-type`}
                                    content="Cancel"
                                    place="top"
                                    variant="light"
                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                  />
                                  <IKTSVG
                                    className="fill-violet-550 transition group-hover:fill-white"
                                    path={CrossSVG}
                                    svgClassName="w-2.5"
                                  />
                                </button>
                                <button
                                  type="button"
                                  id={`confirm-ticket-type`}
                                  className={`${
                                    !buttonShow
                                      ? "bg-gray-100 border-gray-100 pointer-events-none"
                                      : "bg-violet-500 hover:bg-indigo-500 border-violet-500"
                                  }  border  group flex mx-1 items-center justify-center h-6 w-6  hover:border-indigo-500 text-white transition rounded mr-0`}
                                  onClick={() => {
                                    handleConfirm(
                                      show?.data?.order_id,
                                      ticketTypeValue?.id
                                    );
                                  }}
                                >
                                  <IKTSVG
                                    path={CheckSVG}
                                    className={`${
                                      !buttonShow
                                        ? "fill-gray-400"
                                        : "fill-white group-hover:fill-white"
                                    } transition `}
                                    svgClassName="w-3"
                                  />
                                  <Tooltip
                                    anchorId={`confirm-ticket-type`}
                                    content="Confirm"
                                    place="top"
                                    variant="light"
                                    className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10`}
                                  />
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div
                              className={`flex justify-between rounded text-gray-400 text-sm12 `}
                            >
                              <div
                                className={`flex ${dateColor}  flex-1 rounded`}
                              >
                                <div
                                  className={`inline-flex px-2 py-[3px] ${statusColor} text-sm12 text-white rounded-l`}
                                >
                                  {orderDetails?.order_status}
                                </div>
                                <div
                                  className={`inline-flex px-2 py-[3px]  text-sm12 text-violet-800 rounded-r `}
                                >
                                  {legacyCheck === "true" ||
                                  legacyCheck === true ? (
                                    <span className="capitalize">
                                      Legacy order
                                    </span>
                                  ) : orderDetails?.order_status ===
                                      "Cancelled" &&
                                    orderDetails?.internal_order_status !==
                                      "" &&
                                    isGodAdmin ? (
                                    orderDetails?.internal_order_status
                                  ) : (
                                    ticketTypeRenameForSalesReport(
                                      orderDetails?.ticket_type,
                                      orderDetails?.sub_ticket_type
                                    )
                                  )}
                                </div>
                              </div>
                              {!isEditable && isTicketTypeChange && (
                                <button
                                  type="button"
                                  id={`edit-ticket-type`}
                                  className={`group bg-violet-500  hover:bg-indigo-500 border border-violet-500 hover:border-indigo-500 flex mx-2 items-center justify-center h-6 w-6 text-white transition rounded mr-0`}
                                  onClick={(e: any) => setIsEditable(true)}
                                >
                                  <IKTSVG
                                    path={EditSVG}
                                    className={` transition fill-white`}
                                    svgClassName="w-3"
                                  />
                                  <Tooltip
                                    anchorId={`edit-ticket-type`}
                                    content="Edit"
                                    place="top"
                                    variant="light"
                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                  />
                                </button>
                              )}
                            </div>
                          )}
                        </div>

                        {/* {isGodAdmin && (
                            <div className=" md:w-1/2 w-full p-2">
                              <div className="flex gap-1 items-center">
                                {" "}
                                <p className="text-gray-400 text-sm12 flex flex-inline mb-1">
                                  Internal order status
                                </p>{" "}
                                <span
                                  className="ml-2 cursor-pointer mb-1"
                                  onClick={() => setIsStatusLock(!isStatusLock)}
                                >
                                  
                                  <IKTSVG
                                    svgClassName="!w-[.8125rem] !h-[.8125rem]"
                                    path={isStatusLock ? LockIcon : LockIconOpen}
                                  />
                                </span>
                              </div>

                              {isGodAdmin && !isStatusLock ? (
                                <div className="w-full text-sm12 border-violet-900 flex flex-wrap items-center relative">
                                  <TableDropdown
                                    options={
                                      optionsData?.data?.internal_order_statuses
                                    }
                                    name="order_status_id"
                                    placeholder="Order status"
                                    isOrderStatusDropdown="true"
                                    value={optionsData?.data?.internal_order_statuses?.find(
                                      (option: any) =>
                                        option?.name ===
                                        formik?.values?.order_status_id
                                    )}
                                    isClearable={false}
                                    handleOnChange={(e: any) => {
                                      formik.setFieldValue(
                                        `order_status_id`,
                                        e.target.value?.id
                                      );
                                      formik.setFieldValue(
                                        `order_status`,
                                        e.target.value?.description
                                      );
                                    }}
                                    // getOptionLabel="name"
                                    // getOptionValue="id"
                                    isValueCapital={true}
                                    isCapital={true}
                                  />
                                </div>
                              ) : (
                                <p className="text-sm12">
                                  {orderDetails?.internal_order_status
                                    ? orderDetails?.internal_order_status
                                    : "-"}
                                </p>
                              )}
                            </div>
                          )} */}

                        {/* <DetailInfo
                            header="Deliver by"
                            generate
                            headerClassName="text-gray-400 text-sm12 mb-1"
                            value={orderDetails?.deliver_by}
                            valueClassName={`inline-flex px-2.5 py-[3px] ${dateColor} text-sm12 rounded inline-block`}
                          /> */}
                        <div className="md:w-1/2 p-2 w-full flex">
                          <div className="w-1/2">
                            <div className="text-gray-400 text-sm12 mb-1">
                              Deliver by
                            </div>
                            <div
                              className={`text-sm12 capitalize inline-flex px-2.5 py-[3px] ${dateColor} text-sm12 rounded inline-block`}
                            >
                              {orderDetails?.deliver_by
                                ? orderDetails?.deliver_by
                                : ""}
                            </div>
                          </div>
                          <div className="w-1/2">
                            <div className="text-gray-400 text-sm12 mb-1">
                              Days to event
                            </div>
                            <div className="text-sm12 py-[3px]">
                              {orderDetails?.days_left
                                ? orderDetails?.days_left
                                : "0"}
                            </div>
                          </div>
                        </div>

                        {tabValue === "fulfilled" && (
                          <DetailInfo
                            header="Expected payout date"
                            headerClassName="text-gray-400 text-sm12 mb-1"
                            value={moment(orderDetails?.event_datetime)
                              .add(14, "days")
                              .format("DD/MM/YYYY")}
                            // valueClassName="inline-flex px-2.5 py-[3px] bg-rose-500 bg-opacity-[7%] text-sm12 rounded inline-block"
                          />
                        )}

                        {orderDetails?.delivery_partner_name && (
                          <DetailInfo
                            header="Delivery partner"
                            value={orderDetails?.delivery_partner_name}
                          />
                        )}

                        {orderDetails?.fulfilled_ticket_files
                          ?.shipping_tracking_id?.shipping_tracking_id && (
                          <DetailInfo
                            header="Shipping tracking ID"
                            value={
                              orderDetails?.fulfilled_ticket_files
                                ?.shipping_tracking_id?.shipping_tracking_id
                            }
                          />
                        )}

                        {(legacyCheck === "true" || legacyCheck === true) && (
                          <div className="p-2 w-full">
                            <div className="legency bg-rose-500 text-white rounded w-full text-sm12 text-center px-2.5 py-1">
                              Please fulfill legacy orders via the marketplace
                            </div>
                          </div>
                        )}

                        {tabValue === "to_be_fulfilled" &&
                          orderDetails?.ticket_type === "mobile" && (
                            <div className="p-2 w-full">
                              <div
                                className={`legency ${statusColor} text-white rounded w-full text-sm12 text-center px-2.5 py-1`}
                              >
                                Mobile tickets must be delivered directly to the
                                customer's email address or telephone number
                              </div>
                            </div>
                          )}
                      </div>
                    </div>

                    {(orderDetails?.customer_first_name ||
                      orderDetails?.customer_last_name ||
                      orderDetails?.customer_email ||
                      orderDetails?.customer_contact_number ||
                      orderDetails?.customer_city_of_birth ||
                      orderDetails?.customer_nationality) && (
                      <>
                        <div
                          className={`flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0 ${
                            isCustomerLock === true && isGodAdmin
                              ? ""
                              : "shadow-op2 border-transparent"
                          }`}
                        >
                          {/* <div className="title text-sm13 absolute font-semibold bg-white px-[.3125rem] left-[.9375rem] top-0 -translate-y-[50%] leading-4">
                              Customer details
                            </div> */}
                          <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                            <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                              Customer details
                            </h5>
                            {isGodAdmin && (
                              <button
                                type="button"
                                className="w-10 flex items-center justify-center group border-l"
                                onClick={() =>
                                  setIsCustomerLock(!isCustomerLock)
                                }
                              >
                                <IKTSVG
                                  // path={LockIcon}
                                  path={
                                    isCustomerLock ? LockIcon : LockIconOpen
                                  }
                                  svgClassName={`group-hover:fill-indigo-500  h-[.9375rem] ${
                                    isCustomerLock
                                      ? "w-3 fill-violet-500"
                                      : "w-[.8438rem] ml-[.1875rem] fill-indigo-500"
                                  }`}
                                />
                              </button>
                            )}
                          </div>
                          <div className="inner-part px-3 py-3 flex flex-wrap w-full">
                            {isCustomerLock === false && isGodAdmin ? (
                              <>
                                {/* EDIT CUSTOMER INFO */}
                                <div className="flex flex-wrap py-2 form_cols attendee-form lock_form -mt-1">
                                  {orderDetails?.customer_id && (
                                    <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                      <div className="min-w-[6.25rem]  text-sm12 label_text">
                                        Customer ID
                                      </div>
                                      <TextInput
                                        type="text"
                                        value={orderDetails?.customer_id}
                                        required={false}
                                        className="!h-8"
                                        disabled={true}
                                        inputClassName={`bg-gray-100 truncate h-6 text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 border-none !text-violet-800 placeholder:!text-violet-800 disable px-2.5 w-full`}
                                      />
                                    </div>
                                  )}
                                  <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                    <div className="min-w-[6.25rem]  text-sm12 label_text">
                                      Customer first name
                                    </div>
                                    <TextInput
                                      name={`customer_first_name`}
                                      type="text"
                                      value={
                                        formik?.values?.customer_first_name
                                      }
                                      required={false}
                                      placeholder="Enter first name"
                                      className="!h-8"
                                      // errorClass={
                                      //   formik.touched.details?.[index]?.name &&
                                      //   formik.errors.details?.[index]?.name &&
                                      //   "border-rose-500"
                                      // }
                                      handleClearValue={() =>
                                        formik.setFieldValue(
                                          "customer_first_name",
                                          ""
                                        )
                                      }
                                      handleOnChange={formik.handleChange}
                                    />
                                  </div>
                                  <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                    <div className="min-w-[6.25rem] text-sm12 label_text">
                                      Customer last name
                                    </div>
                                    <TextInput
                                      name={`customer_last_name`}
                                      type="text"
                                      value={formik?.values?.customer_last_name}
                                      required={false}
                                      placeholder="Enter last name"
                                      className="!h-8"
                                      // errorClass={
                                      //   formik.touched.details?.[index]?.name &&
                                      //   formik.errors.details?.[index]?.name &&
                                      //   "border-rose-500"
                                      // }
                                      handleClearValue={() =>
                                        formik.setFieldValue(
                                          "customer_last_name",
                                          ""
                                        )
                                      }
                                      handleOnChange={formik.handleChange}
                                    />
                                  </div>
                                  <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                    <div className="min-w-[6.25rem] text-sm12 label_text">
                                      Customer email
                                    </div>
                                    <div className="flex-1 relative">
                                      <TextInput
                                        name={`customer_email`}
                                        type="email"
                                        value={formik?.values?.customer_email}
                                        required={false}
                                        placeholder="Enter Email"
                                        className="!h-8"
                                        errorClass={
                                          formik.touched.customer_email &&
                                          formik.errors.customer_email &&
                                          "border-rose-500"
                                        }
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            "customer_email",
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                      />
                                      {formik.touched.customer_email &&
                                        formik.errors.customer_email && (
                                          <div className="text-xs text-rose-500 error-msg ">
                                            {
                                              formik.errors
                                                .customer_email as string
                                            }
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                    <div className="min-w-[6.25rem] text-sm12 label_text">
                                      Customer contact number
                                    </div>
                                    <div className="flex-1 relative">
                                      <TextInput
                                        name={`customer_contact_number`}
                                        type="text"
                                        value={
                                          formik?.values
                                            ?.customer_contact_number
                                        }
                                        required={false}
                                        placeholder="Enter Contact number"
                                        className="!h-8"
                                        errorClass={
                                          formik.touched
                                            .customer_contact_number &&
                                          formik.errors
                                            .customer_contact_number &&
                                          "border-rose-500"
                                        }
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            "customer_contact_number",
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                        handleKeyDown={handleKeyDown}
                                      />
                                      {formik.touched.customer_contact_number &&
                                        formik.errors
                                          .customer_contact_number && (
                                          <div className="text-xs text-rose-500 error-msg ">
                                            {
                                              formik.errors
                                                .customer_contact_number as string
                                            }
                                          </div>
                                        )}
                                    </div>
                                  </div>

                                  <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                    <div className="min-w-[6.25rem] text-sm12 label_text">
                                      Customer nationality
                                    </div>
                                    <div className="flex-1 relative">
                                      <TextInput
                                        name={`customer_nationality`}
                                        type="text"
                                        value={
                                          formik?.values?.customer_nationality
                                        }
                                        required={false}
                                        placeholder="Enter nationality"
                                        className="!h-8"
                                        errorClass={
                                          formik.touched.customer_nationality &&
                                          formik.errors.customer_nationality &&
                                          "border-rose-500"
                                        }
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            "customer_nationality",
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                      />
                                      {formik.touched.customer_nationality &&
                                        formik.errors.customer_nationality && (
                                          <div className="text-xs text-rose-500 error-msg ">
                                            {
                                              formik.errors
                                                .customer_nationality as string
                                            }
                                          </div>
                                        )}
                                    </div>
                                  </div>

                                  <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                    <div className="min-w-[6.25rem] text-sm12 label_text">
                                      Customer city of birth
                                    </div>
                                    <div className="flex-1 relative">
                                      <TextInput
                                        name={`customer_city_of_birth`}
                                        type="text"
                                        value={
                                          formik?.values?.customer_city_of_birth
                                        }
                                        required={false}
                                        placeholder="Enter city of birth"
                                        className="!h-8"
                                        errorClass={
                                          formik.touched
                                            .customer_city_of_birth &&
                                          formik.errors
                                            .customer_city_of_birth &&
                                          "border-rose-500"
                                        }
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            "customer_city_of_birth",
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                      />
                                      {formik.touched.customer_city_of_birth &&
                                        formik.errors
                                          .customer_city_of_birth && (
                                          <div className="text-xs text-rose-500 error-msg ">
                                            {
                                              formik.errors
                                                .customer_city_of_birth as string
                                            }
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {orderDetails?.customer_id && (
                                  <CopyToClipboard
                                    headerName="Customer ID"
                                    value={`${orderDetails?.customer_id}`}
                                    id={"customerName"}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}
                                {(orderDetails?.customer_first_name ||
                                  orderDetails?.customer_last_name) && (
                                  <CopyToClipboard
                                    headerName="Customer name"
                                    value={`${orderDetails?.customer_first_name} ${orderDetails?.customer_last_name}`}
                                    id={"customerName"}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}

                                {orderDetails?.customer_email && (
                                  <CopyToClipboard
                                    headerName="Customer email"
                                    value={`${
                                      orderDetails?.customer_email
                                        ? orderDetails?.customer_email
                                        : ""
                                    }`}
                                    id={"customerEmail"}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}

                                {orderDetails?.customer_contact_number && (
                                  <CopyToClipboard
                                    headerName="Customer phone"
                                    value={`${
                                      orderDetails?.customer_contact_number
                                        ? orderDetails?.customer_contact_number
                                        : ""
                                    }`}
                                    id={"customer_contact_number"}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}

                                {orderDetails?.customer_nationality && (
                                  <CopyToClipboard
                                    headerName="Customer nationality"
                                    value={`${
                                      orderDetails?.customer_nationality
                                        ? orderDetails?.customer_nationality
                                        : ""
                                    }`}
                                    id={"customer_nationality"}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}

                                {orderDetails?.customer_city_of_birth && (
                                  <CopyToClipboard
                                    headerName="Customer city of birth"
                                    value={`${
                                      orderDetails?.customer_city_of_birth
                                        ? orderDetails?.customer_city_of_birth
                                        : ""
                                    }`}
                                    id={"customer_city_of_birth"}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    <div className=" flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0">
                      <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                        <div className="left_block flex items-center justify-between flex-1 pr-5">
                          <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                            Tickets ordered
                          </h5>

                          {!orderDetails?.is_txtrade_pending_order &&
                            tabValue === "to_be_fulfilled" &&
                            legacyCheck !== true && (
                              <button
                                type="button"
                                className="bg-violet-500 text-white group px-2.5 py-0.5 min-h-[1.5rem] rounded flex items-center text-sm13 hover:bg-indigo-500 transition-all"
                                onClick={(e: any) => {
                                  display(false);
                                  handleShow({
                                    show: false,
                                    data: "",
                                  });
                                  handleOrder(orderDetails);
                                }}
                              >
                                <IKTSVG
                                  className="fill-white mr-[.4375rem]"
                                  path={UploadSVGNew}
                                  svgClassName="w-3.5 h-3"
                                />
                                {orderDetails?.ticket_type === "mobile"
                                  ? "Confirm transfer"
                                  : orderDetails?.ticket_type === "eticket" ||
                                    orderDetails?.ticket_type === "PDF"
                                  ? orderDetails?.ticket_quantity > 1
                                    ? "Deliver"
                                    : "Deliver"
                                  : orderDetails?.ticket_type === "mobile-link"
                                  ? "Confirm mobile link"
                                  : orderDetails?.ticket_type === "paper" ||
                                    orderDetails?.ticket_type === "season-cards"
                                  ? "Confirm shipping"
                                  : "Upload ticket"}
                              </button>
                            )}
                        </div>
                      </div>

                      <div className="flex flex-wrap px-3 py-3 relative">
                        <DetailInfo
                          header="Event description"
                          value={orderDetails?.event_description}
                          valueClassName="text-sm12 overflow-hidden text-ellipsis whitespace-nowrap"
                        />
                        <DetailInfo
                          header="Venue"
                          value={orderDetails?.venue}
                        />
                        <DetailInfo
                          header="Event date"
                          value={
                            orderDetails?.event_date
                              ? moment(orderDetails?.event_date).format(
                                  "ddd, DD MMM YYYY, HH:mm"
                                )
                              : null
                          }
                        />
                        <DetailInfo
                          header="Seat details"
                          value={orderDetails?.seating_location}
                        />

                        <div className="md:w-1/2 p-2 w-full flex">
                          <div className="w-1/2">
                            <div className="text-gray-400 text-sm12">
                              Ticket type
                            </div>
                            <div className="text-sm12 ">
                              {orderDetails?.ticket_type
                                ? ticketTypeRenameForSalesReport(
                                    orderDetails?.ticket_type,
                                    orderDetails?.sub_ticket_type
                                  )
                                : ""}
                            </div>
                          </div>
                          <div className="w-1/2">
                            <div className="text-gray-400 text-sm12">
                              Quantity
                            </div>
                            <div className="text-sm12">
                              {orderDetails?.ticket_quantity
                                ? orderDetails?.ticket_quantity
                                : "0"}
                            </div>
                          </div>
                        </div>

                        <div className="md:w-1/2 p-2 w-full flex">
                          <div className="w-1/2">
                            <div className="text-gray-400 text-sm12">
                              Ticket price
                            </div>
                            <div className="text-sm12">
                              {orderDetails?.ticket_price
                                ? `${
                                    isGodAdmin
                                      ? SYMBOLS[orderDetails.ticket_currency]
                                      : coreLayout.currencySymbol
                                  }${decimalNumber(orderDetails?.ticket_price)}`
                                : "-"}
                            </div>
                          </div>
                          <DetailInfo
                            header="Order value"
                            padding=""
                            value={
                              orderDetails?.transaction_value
                                ? `${
                                    isGodAdmin
                                      ? SYMBOLS[orderDetails.ticket_currency]
                                      : coreLayout.currencySymbol
                                  }${decimalNumber(
                                    orderDetails?.transaction_value
                                  )}`
                                : "-"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap px-3 py-3 border rounded relative mb-5 last-of-type:mb-0">
                      <div className="title text-sm13 absolute font-semibold bg-white px-[.3125rem] left-[.9375rem] top-0 -translate-y-[50%] leading-4">
                        Benefits/Restrictions
                      </div>
                      <div
                        className={`${
                          benefitsRestrictions?.length > 0
                            ? `ben_list -mx-[.3125rem] w-full`
                            : ``
                        } gap-y-1 flex flex-wrap `}
                      >
                        {benefitsRestrictions?.length > 0 ? (
                          benefitsRestrictions?.map(
                            (restriction: any, restKey: number) => {
                              return (
                                <div
                                  className="single_list flex items-center text-xs p-0 md:w-1/2 w-full px-[.3125rem]"
                                  key={`restriction_${restKey}`}
                                >
                                  - {restriction}
                                </div>
                              );
                            }
                          )
                        ) : (
                          <h5 className="font-medium text-xs  w-full px-[.4375rem]">
                            N/A
                          </h5>
                        )}
                      </div>
                    </div>
                    {_.size(ticketItems) > 0 && (
                      <div
                        className={`flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0 ${
                          isTicketLock === true && isGodAdmin
                            ? ""
                            : "shadow-op2 border-transparent"
                        }`}
                      >
                        {/* <div className="title text-sm13 absolute font-semibold bg-white px-[.3125rem] left-[.9375rem] top-0 -translate-y-[50%] leading-4">
                            Attendee details
                          </div> */}
                        <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                          <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                            Attendee details
                          </h5>
                          {isGodAdmin && (
                            <button
                              type="button"
                              className="w-10 flex items-center justify-center group border-l"
                              onClick={() => setIsTicketLock(!isTicketLock)}
                            >
                              <IKTSVG
                                // path={LockIcon}
                                path={isTicketLock ? LockIcon : LockIconOpen}
                                svgClassName={`group-hover:fill-indigo-500 h-[.9375rem] ${
                                  isTicketLock
                                    ? "w-3 fill-violet-500"
                                    : "w-[.8438rem] ml-[.1875rem] fill-indigo-500"
                                }`}
                              />
                            </button>
                          )}
                        </div>
                        {/* {isGodAdmin && (
                            <div
                              className="px-5 py-2 pb-0 lock-icon flex justify-start w-full"
                              onClick={() => setIsTicketLock(!isTicketLock)}
                            >
                              
                              <IKTSVG
                                svgClassName="!w-[.8125rem] !h-[.8125rem]"
                                path={isTicketLock ? LockIcon : LockIconOpen}
                              />
                            </div>
                          )} */}
                        {isTicketLock === false && isGodAdmin ? (
                          <>
                            {/* EDIT TICKIT INFO */}
                            {formik?.values?.items?.map(
                              (obj: any, index: any) => {
                                return (
                                  <div className="last-of-type:border-b-0 border-b">
                                    <div className="flex flex-wrap px-3 py-3 form_cols attendee-form lock_form">
                                      <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                        <div className="min-w-[6.25rem] text-sm12 label_text">
                                          Ticket {index + 1} name
                                        </div>
                                        <TextInput
                                          name={`items[${index}].name`}
                                          type="text"
                                          value={obj?.name}
                                          required={false}
                                          placeholder="Enter ticket name"
                                          className="!h-8"
                                          // errorClass={
                                          //   formik.touched.details?.[index]?.name &&
                                          //   formik.errors.details?.[index]?.name &&
                                          //   "border-rose-500"
                                          // }
                                          handleClearValue={() =>
                                            formik.setFieldValue(
                                              `items[${index}].name`,
                                              ""
                                            )
                                          }
                                          handleOnChange={formik.handleChange}
                                        />
                                      </div>
                                      <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                        <div className="min-w-[6.25rem] text-sm12 label_text">
                                          Row
                                        </div>
                                        <TextInput
                                          name={`items[${index}].row`}
                                          type="text"
                                          value={obj?.row}
                                          required={false}
                                          placeholder="Enter row name"
                                          className="!h-8"
                                          // errorClass={
                                          //   formik.touched.details?.[index]?.name &&
                                          //   formik.errors.details?.[index]?.name &&
                                          //   "border-rose-500"
                                          // }
                                          handleClearValue={() =>
                                            formik.setFieldValue(
                                              `items[${index}].row`,
                                              ""
                                            )
                                          }
                                          handleOnChange={formik.handleChange}
                                        />
                                      </div>
                                      <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                        <div className="min-w-[6.25rem] text-sm12 label_text">
                                          Seat
                                        </div>
                                        <TextInput
                                          name={`items[${index}].seat`}
                                          type="text"
                                          value={obj?.seat}
                                          required={false}
                                          placeholder="Enter seat name"
                                          className="!h-8"
                                          // errorClass={
                                          //   formik.touched.details?.[index]?.name &&
                                          //   formik.errors.details?.[index]?.name &&
                                          //   "border-rose-500"
                                          // }
                                          handleClearValue={() =>
                                            formik.setFieldValue(
                                              `items[${index}].seat`,
                                              ""
                                            )
                                          }
                                          handleOnChange={formik.handleChange}
                                        />
                                      </div>

                                      <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                        <div className="min-w-[6.25rem] text-sm12 label_text">
                                          Email address
                                        </div>
                                        <div className="flex-1 relative">
                                          <TextInput
                                            name={`items[${index}].customer_email_address`}
                                            type="text"
                                            value={obj?.customer_email_address}
                                            required={false}
                                            placeholder="Enter customer email"
                                            className="!h-8"
                                            handleClearValue={() =>
                                              formik.setFieldValue(
                                                `items[${index}].customer_email_address`,
                                                ""
                                              )
                                            }
                                            handleOnChange={formik.handleChange}
                                          />
                                          {formik.touched?.items?.[index]
                                            ?.customer_email_address &&
                                            formik.errors?.items?.[index]
                                              ?.customer_email_address && (
                                              <div className="text-xs text-rose-500 error-msg ">
                                                {
                                                  formik.errors?.items?.[index]
                                                    ?.customer_email_address as string
                                                }
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                      <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                        <div className="min-w-[6.25rem] text-sm12 label_text">
                                          National ID
                                        </div>
                                        <TextInput
                                          name={`items[${index}].customer_national_id`}
                                          type="text"
                                          value={obj?.customer_national_id}
                                          required={false}
                                          placeholder="Enter customer national ID"
                                          className="!h-8"
                                          handleClearValue={() =>
                                            formik.setFieldValue(
                                              `items[${index}].customer_national_id`,
                                              ""
                                            )
                                          }
                                          handleOnChange={formik.handleChange}
                                        />
                                      </div>

                                      <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                        <div className="min-w-[6.25rem] text-sm12 label_text">
                                          Nationality
                                        </div>
                                        <TextInput
                                          name={`items[${index}].customer_nationality`}
                                          type="text"
                                          value={obj?.customer_nationality}
                                          required={false}
                                          placeholder="Enter customer nationality"
                                          className="!h-8"
                                          handleClearValue={() =>
                                            formik.setFieldValue(
                                              `items[${index}].customer_nationality`,
                                              ""
                                            )
                                          }
                                          handleOnChange={formik.handleChange}
                                        />
                                      </div>

                                      <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                        <div className="min-w-[6.25rem] text-sm12 label_text">
                                          Contact number
                                        </div>
                                        <div className="flex-1 relative">
                                          <TextInput
                                            name={`items[${index}].customer_contact_number`}
                                            type="text"
                                            value={obj?.customer_contact_number}
                                            required={false}
                                            placeholder="Enter customer number "
                                            className="!h-8"
                                            handleClearValue={() =>
                                              formik.setFieldValue(
                                                `items[${index}].customer_contact_number`,
                                                ""
                                              )
                                            }
                                            errorClass={
                                              formik.touched?.items?.[index]
                                                ?.customer_contact_number &&
                                              formik.errors?.items?.[index]
                                                ?.customer_contact_number &&
                                              "border-rose-500"
                                            }
                                            handleOnChange={formik.handleChange}
                                          />
                                          {formik.touched?.items?.[index]
                                            ?.customer_contact_number &&
                                            formik.errors?.items?.[index]
                                              ?.customer_contact_number && (
                                              <div className="text-xs text-rose-500 error-msg ">
                                                {
                                                  formik.errors?.items?.[index]
                                                    ?.customer_contact_number as string
                                                }
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                      <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                        <div className="min-w-[6.25rem] text-sm12 label_text">
                                          Birth place
                                        </div>
                                        <TextInput
                                          name={`items[${index}].customer_birth_place`}
                                          type="text"
                                          value={obj?.customer_birth_place}
                                          required={false}
                                          placeholder="Enter customer birth place"
                                          className="!h-8"
                                          handleClearValue={() =>
                                            formik.setFieldValue(
                                              `items[${index}].customer_birth_place`,
                                              ""
                                            )
                                          }
                                          handleOnChange={formik.handleChange}
                                        />
                                      </div>

                                      <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                        <div className="min-w-[6.25rem] text-sm12 label_text">
                                          Customer address
                                        </div>
                                        <TextInput
                                          name={`items[${index}].customer_address`}
                                          type="text"
                                          value={obj?.customer_address}
                                          required={false}
                                          placeholder="Enter customer address"
                                          className="!h-8"
                                          handleClearValue={() =>
                                            formik.setFieldValue(
                                              `items[${index}].customer_address`,
                                              ""
                                            )
                                          }
                                          handleOnChange={formik.handleChange}
                                        />
                                      </div>
                                      <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                        <div className="min-w-[6.25rem] text-sm12 label_text">
                                          Date of birth
                                        </div>
                                        <DatePickerField
                                          name={`items[${index}].customer_dob`}
                                          placeholderMoveTop={false}
                                          disabledKeyboardNavigation={false}
                                          value={obj?.customer_dob}
                                          id={"dateOfBirth"}
                                          isDateRange={false}
                                          IsIconRequire={false}
                                          handleOnChange={(data: any) => {
                                            formik.handleChange(data);
                                          }}
                                          placeholder="DD/MM/YYYY"
                                          format="dd/MM/yy"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </>
                        ) : (
                          ticketItems.map((ticket: any, index: number) => {
                            return (
                              <react.Fragment key={index}>
                                <div className="flex flex-wrap px-3 border-b last:border-none pb-3 mb-3 last:pb-0 last:mb-0 w-full">
                                  {ticket?.name?.trim() && (
                                    <CopyToClipboard
                                      headerName={`Ticket ${index + 1} name`}
                                      value={ticket?.name}
                                      id={index}
                                      handleClick={(data: any) => {
                                        let copiedValues = copy(data?.value);
                                        setIsCopied({
                                          id: data?.id,
                                          value: copiedValues,
                                        });
                                      }}
                                    />
                                  )}

                                  <div className="md:w-1/2 flex flex-wrap w-full">
                                    <DetailInfo
                                      header="Row"
                                      headerClassName="text-gray-400 text-sm12 mb-2"
                                      value={ticket?.row ? ticket?.row : "N/A"}
                                    />
                                    <DetailInfo
                                      header="Seat"
                                      headerClassName="text-gray-400 text-sm12 mb-2"
                                      value={
                                        ticket?.seat ? ticket?.seat : "N/A"
                                      }
                                    />
                                  </div>

                                  {/* MOBILE LINK */}
                                  {ticket?.mobile_link?.trim() && (
                                    <div className="md:w-1/2 p-2 w-full">
                                      <div className="text-gray-400 text-sm12 mb-1">
                                        Link
                                      </div>
                                      <div className="relative inline-flex overflow-hidden text-white rounded group">
                                        <button
                                          type="button"
                                          className="flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition font-medium !rounded-l text-sm13 px-2.5 py-0.5"
                                          onClick={() => {
                                            window.open(
                                              ticket?.mobile_link,
                                              "_blank"
                                            );
                                          }}
                                        >
                                          <IKTSVG
                                            path={externalLink}
                                            className="w-[.6875rem] h-3 mr-2 fill-white"
                                            svgClassName="w-[.6875rem] h-3"
                                          ></IKTSVG>
                                          Link
                                        </button>

                                        <button
                                          title="Copy link"
                                          type="button"
                                          className="flex items-center text-white bg-gray-200 transition font-medium !rounded-r text-sm13 px-2.5 py-0.5"
                                          onClick={(e: any) => {
                                            let data = copy(
                                              ticket?.mobile_link
                                            );
                                            setCopyData({
                                              id: index,
                                              value: data,
                                            });
                                            setTimeout(() => {
                                              setCopyData(undefined);
                                            }, 1000);
                                          }}
                                        >
                                          <IKTSVG
                                            path={CloneSVG}
                                            className={`w-[.6875rem] h-3  
                                          
                                          ${
                                            copyData?.id === index
                                              ? "fill-green-600  hover:fill-green-600"
                                              : "fill-violet-500 hover:fill-indigo-500"
                                          }
                                          `}
                                            svgClassName="w-[.6875rem] h-3"
                                          ></IKTSVG>
                                        </button>
                                      </div>
                                    </div>
                                  )}

                                  {/*CUSTOMER DOB */}
                                  {ticket?.customer_dob && (
                                    <CopyToClipboard
                                      headerName={`Ticket ${index + 1} DOB`}
                                      value={
                                        ticket?.customer_dob
                                          ? moment(ticket.customer_dob).format(
                                              "DD/MM/YYYY"
                                            )
                                          : ``
                                      }
                                      id={index}
                                      handleClick={(data: any) => {
                                        let copiedValues = copy(data?.value);
                                        setIsCopied({
                                          id: data?.id,
                                          value: copiedValues,
                                        });
                                      }}
                                    />
                                  )}
                                  {/*CONTACT PHONE */}
                                  {ticket?.customer_birth_place && (
                                    <CopyToClipboard
                                      headerName={`Ticket ${
                                        index + 1
                                      } birth place`}
                                      value={
                                        ticket?.customer_birth_place
                                          ? ticket?.customer_birth_place
                                          : ``
                                      }
                                      id={index}
                                      handleClick={(data: any) => {
                                        let copiedValues = copy(data?.value);
                                        setIsCopied({
                                          id: data?.id,
                                          value: copiedValues,
                                        });
                                      }}
                                    />
                                  )}

                                  {/*CUSTOMER DOB */}
                                  {/* {ticket?.customer_dob && (
                                <CopyToClipboard
                                  headerName={`Ticket ${index + 1} DOB`}
                                  value={
                                    ticket?.customer_dob
                                      ? ticket?.customer_dob
                                      : ``
                                  }
                                  id={index}
                                  handleClick={(data: any) => {
                                    let copiedValues = copy(data?.value);
                                    setIsCopied({
                                      id: data?.id,
                                      value: copiedValues,
                                    });
                                  }}
                                />
                              )} */}
                                  {/*CONTACT PHONE */}
                                  {ticket?.customer_contact_number && (
                                    <CopyToClipboard
                                      headerName={`Ticket ${
                                        index + 1
                                      } contact number`}
                                      value={
                                        ticket?.customer_contact_number
                                          ? ticket?.customer_contact_number
                                          : ``
                                      }
                                      id={index}
                                      handleClick={(data: any) => {
                                        let copiedValues = copy(data?.value);
                                        setIsCopied({
                                          id: data?.id,
                                          value: copiedValues,
                                        });
                                      }}
                                    />
                                  )}

                                  {/* CUSTOMER EMAIL ADDRESS */}
                                  {ticket?.customer_email_address && (
                                    <CopyToClipboard
                                      headerName={`Ticket ${index + 1} email`}
                                      value={
                                        ticket?.customer_email_address
                                          ? ticket?.customer_email_address
                                          : ``
                                      }
                                      id={index}
                                      handleClick={(data: any) => {
                                        let copiedValues = copy(data?.value);
                                        setIsCopied({
                                          id: data?.id,
                                          value: copiedValues,
                                        });
                                      }}
                                    />
                                  )}

                                  {/*NATIONAL ID */}
                                  {ticket?.customer_national_id && (
                                    <CopyToClipboard
                                      headerName={`Ticket ${
                                        index + 1
                                      } National ID`}
                                      value={
                                        ticket?.customer_national_id
                                          ? ticket?.customer_national_id
                                          : ``
                                      }
                                      id={index}
                                      handleClick={(data: any) => {
                                        let copiedValues = copy(data?.value);
                                        setIsCopied({
                                          id: data?.id,
                                          value: copiedValues,
                                        });
                                      }}
                                    />
                                  )}
                                  {/*NATIONALITY */}
                                  {ticket?.customer_nationality && (
                                    <CopyToClipboard
                                      headerName={`Ticket ${
                                        index + 1
                                      } nationality`}
                                      value={
                                        ticket?.customer_nationality
                                          ? ticket?.customer_nationality
                                          : ``
                                      }
                                      id={index}
                                      handleClick={(data: any) => {
                                        let copiedValues = copy(data?.value);
                                        setIsCopied({
                                          id: data?.id,
                                          value: copiedValues,
                                        });
                                      }}
                                    />
                                  )}

                                  {/*address */}
                                  {ticket?.customer_address && (
                                    <CopyToClipboard
                                      headerName={`Ticket ${index + 1} address`}
                                      value={
                                        ticket?.customer_address
                                          ? ticket?.customer_address
                                          : ``
                                      }
                                      id={index}
                                      handleClick={(data: any) => {
                                        let copiedValues = copy(data?.value);
                                        setIsCopied({
                                          id: data?.id,
                                          value: copiedValues,
                                        });
                                      }}
                                    />
                                  )}

                                  {
                                    PdfTickitCheck &&
                                      orderDetails?.fulfilled_ticket_files?.e_tickets?.map(
                                        // eslint-disable-next-line array-callback-return
                                        (eticket: any, rIndex: any) => {
                                          if (ticket?.id === eticket?.item_id) {
                                            return (
                                              <React.Fragment key={rIndex}>
                                                <div className="flex flex-wrap w-full">
                                                  <div className="md:w-1/2 md:p-2 py-2 w-full">
                                                    <button
                                                      type="button"
                                                      className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
                                                      onClick={() => {
                                                        if (
                                                          eticket?.is_url === 0
                                                        ) {
                                                          downloadPDF(
                                                            eticket?.file,
                                                            "ticket.pdf"
                                                          );
                                                          openBlobPDF(
                                                            eticket?.file
                                                          );
                                                        } else if (
                                                          eticket?.is_url === 1
                                                        ) {
                                                          window.open(
                                                            eticket?.file
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      {loader ? (
                                                        <IKTSVG
                                                          className="flex items-center justify-center fill-violet-500 transition animate-spin"
                                                          path={processSVG}
                                                        />
                                                      ) : (
                                                        <IKTSVG
                                                          className="flex items-center justify-center fill-white transition mr-2 rotate-180"
                                                          path={UploadSVG}
                                                          svgClassName="w-[.8125rem] h-[.6875rem]"
                                                        />
                                                      )}
                                                      Download ticket
                                                    </button>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          }
                                        }
                                      )
                                    // : orderDetails?.ticket_type === "mobile" &&
                                    //   orderDetails?.fulfilled_ticket_files
                                    //     ?.mobile_transfer_proofs?.length > 0 &&
                                    //   orderDetails?.fulfilled_ticket_files?.mobile_transfer_proofs?.map(
                                    //     (item: any, index: number) => {
                                    //       return (
                                    //         <React.Fragment key={index}>
                                    //           <div className="flex flex-wrap w-full">
                                    //             <div className="md:w-1/2 md:p-2 py-2 w-full">
                                    //               <button
                                    //                 type="button"
                                    //                 className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
                                    //                 onClick={() => {
                                    //                   window.open(item);
                                    //                 }}
                                    //               >
                                    //                 {loader ? (
                                    //                   <IKTSVG
                                    //                     className="flex items-center justify-center fill-violet-500 transition animate-spin"
                                    //                     path={processSVG}
                                    //                   />
                                    //                 ) : (
                                    //                   <IKTSVG
                                    //                     className="flex items-center justify-center fill-white transition mr-2 rotate-180"
                                    //                     path={UploadSVG}
                                    //                   />
                                    //                 )}
                                    //                 View ticket
                                    //               </button>
                                    //             </div>
                                    //           </div>
                                    //         </React.Fragment>
                                    //       );
                                    //     }
                                    //   )
                                  }
                                </div>
                              </react.Fragment>
                            );
                          })
                        )}
                      </div>
                    )}

                    {/* for mobile */}
                    {orderDetails?.fulfilled_ticket_files
                      ?.mobile_transfer_proofs?.length > 0 && (
                      <>
                        <div className="ticket-detail  flex flex-wrap border rounded relative mb-5 last-of-type:mb-0">
                          {/* <div className="title  md:px-5 px-3  py-[9px] flex flex-wrap justify-between items-center border-b"> */}
                          {/* <div className="title text-sm13 absolute font-semibold bg-white px-[.3125rem] left-[.9375rem] top-0 -translate-y-[50%] leading-4">
                          Mobile transfer proofs
                        </div> */}
                          <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                            <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                              Mobile transfer proofs
                            </h5>
                          </div>

                          <div className="flex flex-wrap w-full">
                            <div className=" md:px-5 px-5  py-[9px]  w-full flex flex-wrap gap-2.5">
                              {orderDetails?.fulfilled_ticket_files?.mobile_transfer_proofs?.map(
                                (item: any, index: number) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <div className="flex flex-wrap ">
                                        <button
                                          type="button"
                                          className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
                                          onClick={() => {
                                            window.open(item);
                                          }}
                                        >
                                          {loader ? (
                                            <IKTSVG
                                              className="flex items-center justify-center fill-violet-500 transition animate-spin"
                                              path={processSVG}
                                            />
                                          ) : (
                                            <IKTSVG
                                              className="flex items-center justify-center fill-white transition mr-2 rotate-180"
                                              path={UploadSVG}
                                              svgClassName="w-[.8125rem] h-[.6875rem]"
                                            />
                                          )}
                                          Download file
                                        </button>
                                      </div>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {orderDetails?.additional_file && (
                      <div className="flex flex-wrap border rounded relative mb-5 last-of-type:mb-0">
                        <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                          <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                            Additional File
                          </h5>
                        </div>
                        <div className="px-5 py-2 w-full">
                          {/* <div className="title text-sm13 absolute font-semibold bg-white px-[.3125rem] left-[.9375rem] top-0 -translate-y-[50%] leading-4">
                              Additional File
                            </div> */}
                          <div className=" w-full">
                            <button
                              type="button"
                              className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
                              onClick={() => {
                                if (orderDetails?.additional_file) {
                                  window.open(orderDetails?.additional_file);
                                }
                              }}
                            >
                              <IKTSVG
                                className="flex items-center justify-center fill-white transition mr-2 rotate-180"
                                path={UploadSVG}
                                svgClassName="w-[.8125rem] h-[.6875rem]"
                              />
                              Download file
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {(isGodAdmin ||
                      checkShippingAddress ||
                      checkBillingAddress) && (
                      <>
                        <div
                          className={`flex flex-wrap border rounded relative ${
                            isAddressLock === true && isGodAdmin
                              ? ""
                              : "shadow-op2 border-transparent"
                          }`}
                        >
                          {/* <div className="title text-sm13 absolute font-semibold bg-white px-[.3125rem] left-[.9375rem] top-0 -translate-y-[50%] leading-4">
                              Address details
                            </div> */}
                          <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                            <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                              Address details
                            </h5>
                            {isGodAdmin && (
                              <button
                                type="button"
                                className="w-10 flex items-center justify-center group border-l"
                                onClick={() => setIsAddressLock(!isAddressLock)}
                              >
                                <IKTSVG
                                  // path={LockIcon}
                                  path={isAddressLock ? LockIcon : LockIconOpen}
                                  svgClassName={`group-hover:fill-indigo-500  h-[.9375rem] ${
                                    isAddressLock
                                      ? "w-3 fill-violet-500"
                                      : "w-[.8438rem] ml-[.1875rem] fill-indigo-500"
                                  }`}
                                />
                              </button>
                            )}
                          </div>

                          {isAddressLock === false && isGodAdmin ? (
                            <>
                              {/* EDIT BILING ADDRESS */}
                              <div className="flex flex-wrap px-3 py-3 border-b form_cols attendee-form lock_form ">
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Billing address line 1
                                  </div>
                                  <TextInput
                                    name={`customer_billing_address_line_1`}
                                    type="text"
                                    value={
                                      formik?.values
                                        ?.customer_billing_address_line_1
                                    }
                                    required={false}
                                    placeholder="Enter address line 1"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_billing_address_line_1`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Billing address line 2
                                  </div>
                                  <TextInput
                                    name={`customer_billing_address_line_2`}
                                    type="text"
                                    value={
                                      formik?.values
                                        ?.customer_billing_address_line_2
                                    }
                                    required={false}
                                    placeholder="Enter address line 2"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_billing_address_line_2`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Billing address town
                                  </div>
                                  <TextInput
                                    name={`customer_billing_town`}
                                    type="text"
                                    value={
                                      formik?.values?.customer_billing_town
                                    }
                                    required={false}
                                    placeholder="Enter address town"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_billing_town`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Billing address country
                                  </div>
                                  <TextInput
                                    name={`customer_billing_country`}
                                    type="text"
                                    value={
                                      formik?.values?.customer_billing_country
                                    }
                                    required={false}
                                    placeholder="Enter address country"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_billing_country`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Billing address postcode
                                  </div>
                                  <TextInput
                                    name={`customer_billing_postcode`}
                                    type="text"
                                    value={
                                      formik?.values?.customer_billing_postcode
                                    }
                                    required={false}
                                    placeholder="Enter address postcode"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_billing_postcode`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                              </div>

                              {/* EDIT SHIPPING ADDRESS */}
                              <div className="flex flex-wrap px-3 py-3 form_cols attendee-form lock_form ">
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Shipping address line 1
                                  </div>
                                  <TextInput
                                    name={`customer_shipping_address_line_1`}
                                    type="text"
                                    value={
                                      formik?.values
                                        ?.customer_shipping_address_line_1
                                    }
                                    required={false}
                                    placeholder="Enter address line 1"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_shipping_address_line_1`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Shipping address line 2
                                  </div>
                                  <TextInput
                                    name={`customer_shipping_address_line_2`}
                                    type="text"
                                    value={
                                      formik?.values
                                        ?.customer_shipping_address_line_2
                                    }
                                    required={false}
                                    placeholder="Enter address line 2"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_shipping_address_line_2`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Shipping address town
                                  </div>
                                  <TextInput
                                    name={`customer_shipping_town`}
                                    type="text"
                                    value={
                                      formik?.values?.customer_shipping_town
                                    }
                                    required={false}
                                    placeholder="Enter address town"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_shipping_town`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Shipping address country
                                  </div>
                                  <TextInput
                                    name={`customer_shipping_country`}
                                    type="text"
                                    value={
                                      formik?.values?.customer_shipping_country
                                    }
                                    required={false}
                                    placeholder="Enter address country"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_shipping_country`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Shipping address postcode
                                  </div>
                                  <TextInput
                                    name={`customer_shipping_postcode`}
                                    type="text"
                                    value={
                                      formik?.values?.customer_shipping_postcode
                                    }
                                    required={false}
                                    placeholder="Enter address postcode"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_shipping_postcode`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="flex flex-wrap w-full p-3">
                              {isGodAdmin ? (
                                <div className="md:w-1/2 p-2 w-full">
                                  <div className="text-gray-400 text-sm12 mb-1">
                                    Billing address
                                  </div>
                                  <div className="relative">
                                    <textarea
                                      className="whitespace-pre-line scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full text-sm12 rounded bg-gray-100 w-full border-none px-2 block text-violet-800 resize-none min-h-[8.125rem] py-1"
                                      readOnly
                                      value={
                                        !checkBillingAddress
                                          ? "N/A"
                                          : (orderDetails?.customer_billing_address_line_1
                                              ? `${orderDetails?.customer_billing_address_line_1},\n`
                                              : "") +
                                            (orderDetails?.customer_billing_address_line_2
                                              ? `${orderDetails?.customer_billing_address_line_2},\n`
                                              : "") +
                                            (orderDetails?.customer_billing_town
                                              ? `${orderDetails?.customer_billing_town},\n`
                                              : "") +
                                            (orderDetails?.customer_billing_postcode
                                              ? `${orderDetails?.customer_billing_postcode},\n`
                                              : "") +
                                            (orderDetails?.customer_billing_country
                                              ? orderDetails?.customer_billing_country
                                              : "")
                                      }
                                    ></textarea>

                                    {orderDetails?.customer_billing_address_line_1 ||
                                    orderDetails?.customer_billing_address_line_2 ||
                                    orderDetails?.customer_billing_town ||
                                    orderDetails?.customer_billing_postcode ||
                                    orderDetails?.customer_billing_country ? (
                                      <button
                                        className="inline-flex !h-full align-top pt-2  justify-center p-1 absolute top-1/2 right-1 -translate-y-1/2"
                                        type="button"
                                        onClick={(e: any) => {
                                          copy(copyBillingAddress);
                                          setIsCopied("billingAddress");
                                        }}
                                      >
                                        {isCopied === "billingAddress" ? (
                                          <IKTSVG
                                            className="flex items-start justify-center fill-green-600  hover:fill-green-600 transition"
                                            path={CloneSVG}
                                            svgClassName="w-[.7813rem] h-[.6563rem]"
                                          />
                                        ) : (
                                          <IKTSVG
                                            className="flex items-start justify-center fill-violet-500 hover:fill-indigo-500 transition"
                                            path={CloneSVG}
                                            svgClassName="w-[.7813rem] h-[.6563rem]"
                                          />
                                        )}
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              ) : (
                                checkBillingAddress && (
                                  <div className="md:w-1/2 p-2 w-full">
                                    <div className="text-gray-400 text-sm12 mb-1">
                                      Billing address
                                    </div>
                                    <div className="relative">
                                      <textarea
                                        className="whitespace-pre-line scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full text-sm12 rounded bg-gray-100 w-full border-none px-2 block text-violet-800 resize-none min-h-[8.125rem] py-1"
                                        readOnly
                                        //   value={`${
                                        //     orderDetails?.customer_billing_address_line_1
                                        //       ? `${orderDetails?.customer_billing_address_line_1},`
                                        //       : " "
                                        //   }
                                        // ${
                                        //   orderDetails?.customer_billing_address_line_2
                                        //     ? `${orderDetails?.customer_billing_address_line_2},`
                                        //     : " "
                                        // }
                                        // ${
                                        //   orderDetails?.customer_billing_town
                                        //     ? `${orderDetails?.customer_billing_town},`
                                        //     : " "
                                        // }
                                        // ${
                                        //   orderDetails?.customer_billing_postcode
                                        //     ? `${orderDetails?.customer_billing_postcode},`
                                        //     : " "
                                        // }
                                        // ${
                                        //   orderDetails?.customer_billing_country
                                        //     ? `${orderDetails?.customer_billing_country}`
                                        //     : " "
                                        // }
                                        // `}

                                        value={
                                          (orderDetails?.customer_billing_address_line_1
                                            ? `${orderDetails?.customer_billing_address_line_1},\n`
                                            : "") +
                                          (orderDetails?.customer_billing_address_line_2
                                            ? `${orderDetails?.customer_billing_address_line_2},\n`
                                            : "") +
                                          (orderDetails?.customer_billing_town
                                            ? `${orderDetails?.customer_billing_town},\n`
                                            : "") +
                                          (orderDetails?.customer_billing_postcode
                                            ? `${orderDetails?.customer_billing_postcode},\n`
                                            : "") +
                                          (orderDetails?.customer_billing_country
                                            ? orderDetails?.customer_billing_country
                                            : "")
                                        }
                                      ></textarea>

                                      {orderDetails?.customer_billing_address_line_1 ||
                                      orderDetails?.customer_billing_address_line_2 ||
                                      orderDetails?.customer_billing_town ||
                                      orderDetails?.customer_billing_postcode ||
                                      orderDetails?.customer_billing_country ? (
                                        <button
                                          className="inline-flex !h-full align-top pt-2  justify-center p-1 absolute top-1/2 right-1 -translate-y-1/2"
                                          type="button"
                                          onClick={(e: any) => {
                                            copy(copyBillingAddress);
                                            setIsCopied("billingAddress");
                                          }}
                                        >
                                          {isCopied === "billingAddress" ? (
                                            <IKTSVG
                                              className="flex items-start justify-center fill-green-600  hover:fill-green-600 transition"
                                              path={CloneSVG}
                                              svgClassName="w-[.7813rem] h-[.6563rem]"
                                            />
                                          ) : (
                                            <IKTSVG
                                              className="flex items-start justify-center fill-violet-500 hover:fill-indigo-500 transition"
                                              path={CloneSVG}
                                              svgClassName="w-[.7813rem] h-[.6563rem]"
                                            />
                                          )}
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                              {/* {checkBillingAddress && ( */}

                              {/* // )} */}

                              {isGodAdmin ? (
                                <div className="md:w-1/2 p-2 w-full">
                                  <div className="text-gray-400 text-sm12 mb-1">
                                    Shipping address
                                  </div>

                                  <div className="relative">
                                    <textarea
                                      className="whitespace-pre-line scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full text-sm12 rounded bg-gray-100 w-full border-none px-2 block text-violet-800 resize-none min-h-[8.125rem] py-1"
                                      disabled
                                      readOnly
                                      value={
                                        !checkShippingAddress
                                          ? "N/A"
                                          : (orderDetails?.customer_shipping_address_line_1
                                              ? `${orderDetails.customer_shipping_address_line_1},\n`
                                              : "") +
                                            (orderDetails?.customer_shipping_address_line_2
                                              ? `${orderDetails.customer_shipping_address_line_2},\n`
                                              : "") +
                                            (orderDetails?.customer_shipping_town
                                              ? `${orderDetails.customer_shipping_town},\n`
                                              : "") +
                                            (orderDetails?.customer_shipping_postcode
                                              ? `${orderDetails.customer_shipping_postcode},\n`
                                              : "") +
                                            (orderDetails?.customer_shipping_country
                                              ? orderDetails.customer_shipping_country
                                              : "")
                                      }
                                    ></textarea>

                                    {orderDetails?.customer_shipping_address_line_1 ||
                                    orderDetails?.customer_shipping_address_line_2 ||
                                    orderDetails?.customer_shipping_town ||
                                    orderDetails?.customer_shipping_postcode ||
                                    orderDetails?.customer_shipping_country ? (
                                      <button
                                        className="inline-flex !h-full align-top pt-2  justify-center p-1 absolute top-1/2 right-1 -translate-y-1/2"
                                        type="button"
                                        onClick={(e: any) => {
                                          copy(copyShippingAddress);
                                          setIsCopied("shippingAddress");
                                        }}
                                      >
                                        {isCopied === "shippingAddress" ? (
                                          <IKTSVG
                                            className="flex items-start justify-center fill-green-600  hover:fill-green-600 transition"
                                            path={CloneSVG}
                                            svgClassName="w-[.7813rem] h-[.6563rem]"
                                          />
                                        ) : (
                                          <IKTSVG
                                            className="flex items-start justify-center fill-violet-500 hover:fill-indigo-500 transition"
                                            path={CloneSVG}
                                            svgClassName="w-[.7813rem] h-[.6563rem]"
                                          />
                                        )}
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              ) : (
                                checkShippingAddress && (
                                  <div className="md:w-1/2 p-2 w-full">
                                    <div className="text-gray-400 text-sm12 mb-1">
                                      Shipping address
                                    </div>

                                    <div className="relative">
                                      <textarea
                                        className="whitespace-pre-line scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full text-sm12 rounded bg-gray-100 w-full border-none px-2 block text-violet-800 resize-none min-h-[8.125rem] py-1"
                                        disabled
                                        readOnly
                                        value={
                                          (orderDetails?.customer_shipping_address_line_1
                                            ? `${orderDetails.customer_shipping_address_line_1},\n`
                                            : "") +
                                          (orderDetails?.customer_shipping_address_line_2
                                            ? `${orderDetails.customer_shipping_address_line_2},\n`
                                            : "") +
                                          (orderDetails?.customer_shipping_town
                                            ? `${orderDetails.customer_shipping_town},\n`
                                            : "") +
                                          (orderDetails?.customer_shipping_postcode
                                            ? `${orderDetails.customer_shipping_postcode},\n`
                                            : "") +
                                          (orderDetails?.customer_shipping_country
                                            ? orderDetails.customer_shipping_country
                                            : "")
                                        }
                                      ></textarea>

                                      {orderDetails?.customer_shipping_address_line_1 ||
                                      orderDetails?.customer_shipping_address_line_2 ||
                                      orderDetails?.customer_shipping_town ||
                                      orderDetails?.customer_shipping_postcode ||
                                      orderDetails?.customer_shipping_country ? (
                                        <button
                                          className="inline-flex !h-full align-top pt-2  justify-center p-1 absolute top-1/2 right-1 -translate-y-1/2"
                                          type="button"
                                          onClick={(e: any) => {
                                            copy(copyShippingAddress);
                                            setIsCopied("shippingAddress");
                                          }}
                                        >
                                          {isCopied === "shippingAddress" ? (
                                            <IKTSVG
                                              className="flex items-start justify-center fill-green-600  hover:fill-green-600 transition"
                                              path={CloneSVG}
                                              svgClassName="w-[.7813rem] h-[.6563rem]"
                                            />
                                          ) : (
                                            <IKTSVG
                                              className="flex items-start justify-center fill-violet-500 hover:fill-indigo-500 transition"
                                              path={CloneSVG}
                                              svgClassName="w-[.7813rem] h-[.6563rem]"
                                            />
                                          )}
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div className=" flex md:hidden flex-wrap border rounded relative mt-5 mb-5 last-of-type:mb-0">
                    <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                      <div className="left_block flex items-center justify-between flex-1 pr-5">
                        <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                          Payment details
                        </h5>
                      </div>
                    </div>

                    <div className="flex flex-wrap px-3 py-3 relative">
                      <DetailInfo
                        header="Transaction value"
                        value={
                          orderDetails?.transaction_value
                            ? `${
                                SYMBOLS?.[orderDetails?.ticket_currency]
                              }${decimalNumber(
                                orderDetails?.transaction_value
                              )}`
                            : "-"
                        }
                        valueClassName="text-sm12 overflow-hidden text-ellipsis whitespace-nowrap"
                      />
                      <DetailInfo
                        header="Payment method"
                        value="-"
                        valueClassName="text-sm12 overflow-hidden text-ellipsis whitespace-nowrap"
                      />
                      <DetailInfo
                        header="Transaction ID"
                        value="-"
                        valueClassName="text-sm12 overflow-hidden text-ellipsis whitespace-nowrap"
                      />
                    </div>
                  </div>
                </div>{" "}
              </>
            )}

            {/* NAVIGATION BUTTONS */}
            <div
              className={`w-full flex ${
                isGodAdmin ? "justify-between" : "justify-end"
              }  px-5 py-3 bg-white z-10 border-t min-h-[3.1875rem]`}
            >
              <div className="btns flex flex-wrap items-center tx-pay-module gap-3.5">
                {isGodAdmin &&
                  (isAddressLock === false ||
                    isCustomerLock === false ||
                    isTicketLock === false ||
                    isStatusLock === false ||
                    isadministrationLock?.status === false ||
                    isPenaltyLock === false) && (
                    <>
                      <button
                        type="button"
                        className="btn flex-1 text-sm13 font-medium py-1 px-2.5"
                        onClick={() => {
                          display(false);
                          handleShow({
                            show: false,
                            data: "",
                          });
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        type="submit"
                        className={` ${
                          updateLoader && "pointer-events-none !bg-gray-300"
                        }  text-sm13 font-medium py-1 px-2.5 bg-green-600 hover:bg-indigo-500 !text-white rounded border-hidden	 hover:border-hidden	 flex`}
                        onClick={() => {
                          if (_.size(formik?.errors) > 0) {
                            Object.keys(formik?.errors).forEach((errorKey) => {
                              Object.keys(DETAILWISE_ERRROR).forEach(
                                (stateKey) => {
                                  if (
                                    DETAILWISE_ERRROR[stateKey].includes(
                                      errorKey
                                    )
                                  )
                                    ERROR_WISE_UNLOCK[stateKey](false);
                                }
                              );
                            });
                          }
                        }}
                      >
                        {updateLoader && (
                          <IKTSVG
                            className="flex items-center justify-center fill-violet-500 transition animate-spin mr-2"
                            path={processSVG}
                          />
                        )}
                        Update
                      </button>
                    </>
                  )}
              </div>

              <div>
                {/* {/ BACK BUTTON /} */}
                <NavigationButtons
                  id="back"
                  imgPath={angleLeftSVG}
                  // className={
                  //   show?.index === 0 && orders.length > 1 ? "" : show?.index
                  // }
                  className={
                    // isGodAdmin
                    //   ? hasLockedOrder?.length === layout.orders?.length
                    //   :
                    show?.index === 0
                      ? "pointer-events-none"
                      : orders.length - 1 === show?.index
                  }
                  svgClassName={
                    // isGodAdmin
                    //   ? hasLockedOrder?.length === layout.orders?.length
                    //   :
                    show?.index === 0
                      ? "!fill-gray-400"
                      : orders.length - 1 === show?.index
                  }
                  handleNavigation={(data: any) => handleNavigation(data)}
                  disabled={
                    isGodAdmin &&
                    ((unLockedOrders?.length <= 1 &&
                      tabValue === "to_be_fulfilled") ||
                      !isPenaltyLock ||
                      isAddressLock === false ||
                      isCustomerLock === false ||
                      isTicketLock === false ||
                      orderText?.show === true ||
                      isadministrationLock?.status === false)
                      ? true
                      : false
                  }
                />
                {/* {/ NEXT BUTTON /} */}
                <NavigationButtons
                  id="next"
                  imgPath={angleLeftSVG}
                  // className={
                  //   show?.index === 0
                  //     ? "disable"
                  //     : orders.length - 1 === show?.index
                  // }
                  className={
                    // isGodAdmin
                    //   ? hasLockedOrder?.length === layout.orders?.length
                    //   :
                    orders.length - 1 === show?.index
                      ? "pointer-events-none"
                      : ""
                  }
                  svgClassName={
                    // isGodAdmin
                    //   ? hasLockedOrder?.length === layout.orders?.length
                    //   :
                    orders.length - 1 === show?.index ? "!fill-gray-400" : "enn"
                  }
                  handleNavigation={(data: any) => handleNavigation(data)}
                  disabled={
                    isGodAdmin &&
                    ((unLockedOrders?.length <= 1 &&
                      tabValue === "to_be_fulfilled") ||
                      !isPenaltyLock ||
                      isAddressLock === false ||
                      isCustomerLock === false ||
                      isTicketLock === false ||
                      orderText?.show === true ||
                      isadministrationLock?.status === false)
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* ORDER REPLACEMENET FOR NEW LISTING */}
      {layout?.orderAssign?.status && <OrderNewListing />}

      {/* ORDER ASSIGN TO NEW TEAM */}
      {layout?.orderAssignNewTeam?.status && <OrderNewTeam />}

      {progressLoader && <ProgressBardLoader secondCounter={2} />}
    </>
  );
};

export default OrderDetail;
