/* eslint-disable @typescript-eslint/no-unused-vars */
import _, { isArray } from "lodash";
import moment from "moment";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import CollapseSVG from "../../../assets/media/icons/other_icons/Collapse.svg";
import MarketInsightSVG from "../../../assets/media/icons/other_icons/Market-Insights-Green.svg";
import haxPublishSVG from "../../../assets/media/icons/other_icons/hax-Publish-Gray.svg";
import unpublish from "../../../assets/media/icons/other_icons/new-unpublish.svg";
import CalenderSVG from "../../../assets/media/icons/standard_icons/calendar.svg";
import ClockOutlineSVG from "../../../assets/media/icons/standard_icons/clock-outline.svg";
import GeoSVG from "../../../assets/media/icons/standard_icons/geo_icon.svg";
import ListingLinesSVG from "../../../assets/media/icons/standard_icons/listing_lines.svg";
import subtractionSVG from "../../../assets/media/icons/standard_icons/subtraction.svg";
import warningSVG from "../../../assets/media/icons/standard_icons/warning1.svg";
import TablePopup from "../../../components/tableComponent/TablePopup";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { MarketInsight } from "../../add_inventory/table/components/MarketInsight";

import { LayoutContext } from "../../../components/core/LayoutProvider";
import { addCommaInNumber, handleScroll } from "../../../helpers/Functions";
import {
  fetchMarketplacePrice,
  fetchMarketplacePriceWithSection,
} from "../../add_inventory/core/_requsets";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { visibilityMeterCalculationForInventory } from "../core/_functions";
import { getInventoryRecordListing } from "../core/_request";
import EventFields3 from "./component/EventFields3";
import EventHeader from "./component/EventHeader";

const EventTable = memo(
  ({
    layout,
    list,
    event,
    eIndex,
    handleTableField,
    users,
    setList,
    publishStatus,
    eventCheckBox,
    loadMoreListing,
    loadAllEventListing,
    handlePPETableChange,
    updateVisibility,
    tableRefSide,
    editData,
    setEditData,
    isOpenTicketPopUp,
    setIsOpenTicketPopUp,
    matchIndexView,
    setMatchIndexView,
    loadFlag,
    teamsSelected,
    isLoading,
    query,
    currentRecordId,
    setCurrentRecordId,
    SortingCurrentID,
    setSortingCurrentID,
    setIsOpenListingQualityPopup,
    setEditView,
  }: any) => {
    // const layout = useContext(InventoryContext);
    const events: any = list;
    const [tableAlert, setTableAlert] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");
    const [customLoader, setCustomerLoader] = useState<boolean>(false);
    const eventId = layout.eventId;
    const setEventId = layout.setEventId;
    const [collapse, setCollapse] = useState(false);

    const newInventoryFilter = layout.inventoryNewFilterValue;
    const globalLayout = useContext(LayoutContext);

    //taking listing ids and assigning in object
    const listingIds = globalLayout?.editListings;

    const conversation_rate = globalLayout?.conversationRate;
    const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;
    const priceFactor = Number(
      globalLayout?.allowedAccess?.price_suggestion_factor
    );

    // const events = useSelector((state: RootState) => state.events.events);
    // const dispatch = useDispatch();

    // // COLLAPSE HADNLE TOOLTIP
    // const handleCollapseAll = useCallback((id: any) => {
    //   const element: any = document.getElementById(`${id}`);
    //   if (element) {
    //     const ariaExpandedValue = element.getAttribute("aria-expanded");
    //     const isExpanded = ariaExpandedValue === "true";
    //     setCollapse(isExpanded);
    //   }
    // }, []);

    // // COLLAPSE ALL BTN CLICKED DATA
    // useEffect(() => {
    //   const collapsedAllBtn: any = document.getElementById("collapsed");
    //   // Define a click event handler
    //   const handleButtonClick = () => {
    //     // Check the text content of the button
    //     const buttonText = collapsedAllBtn.textContent;
    //     if (buttonText === "Expand all") {
    //       setCollapse(true);
    //     } else {
    //       setCollapse(false);
    //     }
    //   };
    //   if (collapsedAllBtn) {
    //     collapsedAllBtn?.addEventListener("click", handleButtonClick);
    //   }
    //   return () => {
    //     collapsedAllBtn.removeEventListener("click", handleButtonClick);
    //   };
    // }, []);

    const handleEventScroll = (eventId: any) => {
      if (!event?.isOpen) {
        setTimeout(() => {
          const eventContainer: any = document.getElementById(
            "EventAccordion" + eventId
          ) as HTMLElement;
          if (eventContainer) {
            const filters_block: any =
              document.getElementById("filters_block")?.clientHeight;
            const inventoryFilter: any =
              document.getElementById("InventoryFilter")?.clientHeight;
            const headingOne: any =
              document.getElementById("headingOne")?.clientHeight;
            const HeaderHeight: any =
              document.querySelector("header")?.clientHeight;
            // const topOffset = 190;
            const inventoryaccordion: any =
              document.getElementById("inventoryaccordion")?.clientHeight;
            // const topOffset: any =
            //   filters_block + inventoryFilter + HeaderHeight + 15;

            let topOffset: any = 0;
            if (window.matchMedia("(max-width: 767px)").matches) {
              topOffset = HeaderHeight + headingOne;
            } else {
              topOffset = filters_block + inventoryFilter + HeaderHeight + 15;
            }
            const elementPosition: any =
              eventContainer.getBoundingClientRect().top +
              window.scrollY -
              topOffset;
            // Scroll to the calculated position smoothly
            window.scrollTo({
              top: elementPosition,
              behavior: "smooth",
              left: 0,
            });
          }
        }, 100);
      }
    };

    function calculateAvgPriceForProceedValue(
      categoryname: any,
      markplaceArry: any,
      record: any,
      event: any
    ) {
      const section =
        record?.section && record?.section?.id
          ? record?.section
          : record?.section &&
            event?.categories &&
            Array.isArray(event?.categories) &&
            event?.categories
              .find(
                (category: any) =>
                  Number(category.id) === Number(record?.category)
              )
              ?.sections?.find(
                (item: any) => Number(item?.id) === Number(record?.section)
              );
      let sectionValue = section?.originalName
        ?.trim()
        ?.toLowerCase()
        .split(" ")
        .join("");
      let markeplaceValueWithAvg: any =
        markplaceArry &&
        Object.entries(markplaceArry)?.map((item: any) => {
          return {
            id: item?.[0],
            name: item?.[0]?.trim()?.toLowerCase().split(" ").join(""),
            avgPrice: item?.[1]?.total?.price_avg,
          };
        });
      let avgPrice = markeplaceValueWithAvg?.find(
        (obj: any) => obj.name === sectionValue
      )?.avgPrice;
      const conversationRate = isGodAdmin
        ? conversation_rate?.[record?.sell_price_currency]
        : conversation_rate;
      let finalAvgRate = avgPrice ? avgPrice * conversationRate : "";
      return finalAvgRate;
    }

    // HANDLE FOR GET EVENT RECORD WITH API ->  inventory/event/${id}/lists
    const handleEventId = useCallback(
      async (id: any, newEvent: any) => {
        const updatedValues = [...eventId, id];
        if (eventId.includes(id)) {
          let newArray = eventId.filter((item: any) => item !== id);
          setEventId(newArray);
        } else {
          setEventId(updatedValues);
        }

        //  GET IF EVENTRECORD EXIST
        let eventRecordExist = list
          ?.filter((event: any) => event?.id === id)
          ?.find((item: any) => item?.eventRecords);

        // IF RECORD NOT EXIST THEN ALLOW TO CALL API
        if (
          eventRecordExist === undefined ||
          !eventRecordExist ||
          _.size(eventRecordExist?.eventRecords) === 0
        ) {
          setCustomerLoader(true);
          let userId =
            users &&
            Array.isArray(users) &&
            users.length > 0 &&
            _.map(users, "id");
          let publishStatusId =
            publishStatus &&
            Array.isArray(publishStatus) &&
            publishStatus.length > 0 &&
            _.map(publishStatus, "id");

          try {
            getInventoryRecordListing({
              id,
              userId,
              publishStatusId,
              teamsSelected,
              orderState: layout.orderState,
              per_page: 20,
              query,
              newInventoryFilter,
              listingIds,
            }).then((response) => {
              setList((prev: any) => {
                return prev.map((event: any) => {
                  if (event?.id === id) {
                    let final = {
                      ...event,
                      isOpen: !event?.isOpen,
                      eventRecords: response?.data?.map((item: any) => {
                        let visibility = visibilityMeterCalculationForInventory(
                          event,
                          item,
                          layout.ticketType
                        );

                        return {
                          ...item,
                          priceFactor: priceFactor,
                          sub_ticket_type:
                            item?.ticket_type === "eticket" &&
                            item?.sub_ticket_type === ""
                              ? "pdf"
                              : item?.ticket_type === "eticket" &&
                                item?.sub_ticket_type
                              ? item?.sub_ticket_type
                              : "",
                          benefits: item?.restrictions,
                          ticketsInHands: item?.in_hand === "no" ? false : true,
                          visibility: visibility,
                          oldVisibility: 0,
                          exchanges: isArray(event?.selected_event_exchanges)
                            ? event?.selected_event_exchanges
                            : [],
                          isLock: true,
                          loadFlagStatus: true,
                        };
                      }),
                      meta: response?.meta,
                    };
                    if (final?.total_listings > 20) {
                      final = {
                        ...final,
                      };
                    }

                    return final;
                  } else {
                    return event;
                  }
                });
              });

              setTimeout(() => {
                handleEventScroll(event?.id);

                setTimeout(() => {
                  setList((prev: any) => {
                    return prev.map((event: any) => {
                      if (event?.id === id) {
                        let final = {
                          ...event,
                        };
                        if (final?.total_listings > 20) {
                          final = {
                            ...final,
                            loadFlagStatus: true,
                          };
                        }
                        return final;
                      } else {
                        return event;
                      }
                    });
                  });
                }, 50);
              }, 0.5);

              setCustomerLoader(false);
            });

            // const markinsight = result?.data?.results?.setting_types;

            // CALL API TO GET EVENT RECORDS
          } catch (error) {}
          try {
            let responsedata: any;
            const result: any = fetchMarketplacePriceWithSection(event)
              .then((res) => {
                responsedata = res;
              })
              .catch((err) => {})
              .finally(() => {
                const markinsight = responsedata?.data?.results?.sections;
                // setTimeout(() => {
                setList((prev: any) => {
                  return prev.map((event: any) => {
                    if (event?.id === id) {
                      let final = {
                        ...event,
                        eventRecords: event?.eventRecords?.map((item: any) => {
                          const avg_price = calculateAvgPriceForProceedValue(
                            item?.section,
                            markinsight,
                            item,
                            event
                          );
                          return {
                            ...item,
                            priceFactor: priceFactor,

                            markinsight: markinsight,
                            avg_price:
                              markinsight && !avg_price
                                ? calculateAvgPriceForProceedValue(
                                    item?.section,
                                    markinsight,
                                    item,
                                    event
                                  )
                                : avg_price,
                          };
                        }),
                      };

                      return final;
                    } else {
                      return event;
                    }
                  });
                });
                // }, 100);
              });
          } catch (error) {}
        } else {
          handleEventScroll(id);
          setTimeout(() => {
            setList((prev: any) => {
              return prev.map((event: any) => {
                if (event?.id === id) {
                  let element = document.getElementById(event?.id);
                  if (
                    element &&
                    element?.getAttribute("aria-expanded") === "false"
                  ) {
                    // layout.setSelectAllConfirmation(false);
                    return {
                      ...event,
                      requireSelectAll: {},
                      eventRecords: event?.eventRecords?.map((record: any) => {
                        return {
                          ...record,
                          selected: false,
                        };
                      }),
                      isOpen: !event?.isOpen,
                      loadFlagStatus: true,
                    };
                  } else {
                    let final = {
                      ...event,
                      isOpen: !event?.isOpen,
                      loadFlagStatus: true,
                    };
                    return final;
                  }
                } else {
                  return event;
                }
              });
            });
          }, 300);
        }
      },
      [
        eventId,
        list,
        setEventId,
        users,
        publishStatus,
        teamsSelected,
        layout?.inventoryNewFilterValue,
      ]
    );

    const handleMarketplaceInsights = useCallback(async (data: any) => {
      if (!data?.markeInsights) {
        setList((current: any) => {
          return current.map((innerEvent: any) => {
            return data?.id === innerEvent.id
              ? { ...innerEvent, marketInsightLoader: true }
              : innerEvent;
          });
        });
        let response: any;
        fetchMarketplacePrice(data)
          .then((res) => {
            response = res;
          })
          .catch((err) => {})
          .finally(() => {
            setList((current: any) => {
              return current.map((innerEvent: any) => {
                return data?.id === innerEvent.id
                  ? {
                      ...innerEvent,
                      markeInsights: response,
                      marketInsightLoader: false,
                      isOpenMarket: true,
                    }
                  : innerEvent;
              });
            });
          });
      } else {
        setList((current: any) => {
          return current.map((innerEvent: any) => {
            return data?.id === innerEvent.id
              ? { ...innerEvent, isOpenMarket: !innerEvent?.isOpenMarket }
              : innerEvent;
          });
        });
      }
    }, []);

    // SORTING EVENT TABLE
    const handleSortAction = useCallback(
      (orderDetail: any) => {
        if (orderDetail.order) {
          let userId =
            users &&
            Array.isArray(users) &&
            users.length > 0 &&
            _.map(users, "id");
          // if (userSpecific) {
          //   userId = [authUser?.id];
          // }
          let id = orderDetail?.id;
          let orderState = orderDetail;
          let publishStatusId =
            publishStatus &&
            Array.isArray(publishStatus) &&
            publishStatus.length > 0 &&
            _.map(publishStatus, "id");
          if (id) {
            setCustomerLoader(true);
            getInventoryRecordListing({
              id,
              userId,
              publishStatusId,
              orderState,
              query,
              teamsSelected,
              newInventoryFilter: layout.inventoryNewFilterValue,
              listingIds,
            }).then((response) => {
              setCustomerLoader(false);
              setList((prev: any) => {
                return prev.map((InEvent: any) => {
                  if (InEvent?.id === id) {
                    return {
                      ...InEvent,
                      total_listings: response?.meta?.total,
                      requireSelectAll: {},
                      eventRecords: response.data?.map((item: any) => {
                        let visibility: any =
                          visibilityMeterCalculationForInventory(
                            InEvent,
                            item,
                            layout.ticketType
                          );

                        const markeInsights =
                          InEvent?.markeInsights?.data?.results?.sections;
                        const avg_price = calculateAvgPriceForProceedValue(
                          item?.section,
                          markeInsights,
                          item,
                          InEvent
                        );
                        return {
                          ...item,
                          benefits: item?.restrictions,
                          ticketsInHands: item?.in_hand === "no" ? false : true,
                          visibility: visibility,
                          oldVisibility: 0,
                          exchanges: isArray(event?.selected_event_exchanges)
                            ? event?.selected_event_exchanges
                            : [],
                          isLock: true,
                          markinsight: markeInsights,
                          avg_price: avg_price,
                        };
                      }),
                      meta: response?.meta,
                    };
                  } else {
                    return InEvent;
                  }
                });
              });
            });
          }
        }
      },
      [layout.orderState]
    );

    const eventFieldsCompo = useMemo(() => {
      return (
        <EventFields3
          layout={layout}
          list={list}
          setList={setList}
          eventLoader={customLoader}
          event={event}
          eIndex={eIndex}
          handleTableField={handleTableField}
          loadMoreListing={loadMoreListing}
          loadAllEventListing={loadAllEventListing}
          handlePPETableChange={handlePPETableChange}
          updateVisibility={updateVisibility}
          tableRefSide={tableRefSide}
          editData={editData}
          setEditData={setEditData}
          isOpenTicketPopUp={isOpenTicketPopUp}
          setIsOpenTicketPopUp={setIsOpenTicketPopUp}
          matchIndexView={matchIndexView}
          setMatchIndexView={setMatchIndexView}
          loadFlag={loadFlag}
          handleSortAction={handleSortAction}
          currentRecordId={currentRecordId}
          setCurrentRecordId={setCurrentRecordId}
          SortingCurrentID={SortingCurrentID}
          setSortingCurrentID={setSortingCurrentID}
          setIsOpenListingQualityPopup={setIsOpenListingQualityPopup}
          setEditView={setEditView}
        />
      );
    }, [event, list, customLoader, currentRecordId, SortingCurrentID]);

    // const MemoizedHandleTableField = useCallback(
    //   (data: any, eIndex: number) => {
    //     layout.setList((prev: any) => {
    //       return prev.map((InEvent2: any, InIndex: number) => {
    //         //return InEvent2;
    //         if (InIndex === eIndex) {

    //           return {
    //             ...InEvent2,
    //             [data?.target?.name]: data?.target?.value
    //           }
    //         } else {
    //           return InEvent2;
    //         }
    //       });
    //     });
    //   },
    //   []
    // )

    // auto disabled left right table button
    useEffect(() => {
      const element = document.getElementById(event?.id + "lazyLoad");
      const stickyHead = document.getElementById(
        "EventCollapseBody" + event?.id
      );

      if (element) {
        handleScroll(element, stickyHead);
        let scrollTimeout: any;

        const scrollHandler = () => {
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(() => {
            handleScroll(element, stickyHead);
          }, 500);
        };

        element.addEventListener("scroll", scrollHandler);
        scrollHandler();

        return () => {
          // Clean up event listener and timeout on unmount
          element.removeEventListener("scroll", scrollHandler);
          clearTimeout(scrollTimeout);
        };
      }
    }, [event]); // Dependency array with 'event' will trigger effect on event changes

    return (
      <React.Fragment key={`EventRecordTAble${event?.id}`}>
        {tableAlert && (
          <TablePopup
            isConfirm={false}
            message={msgAlert && msgAlert}
            cancelBtn={(data: any) => setTableAlert(data)}
          />
        )}

        <div
          id="table-shrink"
          className={`inventory-list 
          ${
            event?.isOpen &&
            event?.eventRecords?.length > 0 &&
            event?.loadFlagStatus &&
            event?.total_listings !== event?.eventRecords?.length
              ? "below-hidden-events"
              : ""
          }
          ${
            event?.isOpen &&
            Object.keys(layout?.tableAlert).length &&
            layout?.tableAlert?.confirm
              ? "bulk-action"
              : ""
          }
          
          ${event?.isOpenMarket ? "marketInsight-open" : ""}

          `}
        >
          <div
            className={`accordion font-medium mb-2.5  ${
              event?.isOpenMarket || event?.isOpen ? "md:mb-5" : "mb-2.5"
            }`}
            id={`EventAccordion${event?.id}`}
            data-id={event.id}
          >
            {/* {JSON.stringify(event.id)} */}
            <div
              className={`accordion-item bg-white !rounded ${
                isLoading && "shimmer-effect"
              } `}
            >
              <div
                className={`${
                  layout.tableAlert?.confirm || layout.isBulkActionLoader
                    ? "pointer-events-none"
                    : ""
                }  flex accordion-header mb-0  ${
                  event?.isOpen ? "event__header" : "rounded-b"
                }  event__header bg-violet-700 rounded-t text-white custom-head `}
                id={`EventHeading${event?.id}`}
              >
                <div
                  className={`flex lg2:flex-nowrap flex-wrap w-full cursor-pointer title-accordion ${
                    layout.selectAllLoaderCurrentEvent === event.id &&
                    "!pointer-events-none"
                  }`}
                  data-te-collapse-init
                  data-te-collapse-collapsed
                  data-te-target={`${`#EventCollapseBody${event?.id}`}`}
                  aria-expanded="false"
                  aria-controls={`${`EventCollapseBody${event?.id}`}`}
                  id={event.id}
                  onClick={(e: any) => {
                    setTimeout(() => {
                      handleEventScroll(event.id);
                    }, 100);
                    handleEventId(event.id, event);
                  }}
                >
                  {/* <div className="accordion-search flex lg2:flex-nowrap flex-wrap lg:flex-auto md:flex-1 md:mr-4">
                    <div
                      className={`${
                        event.id === ""
                          ? "xl:max-w-[30rem] !p-1.5"
                          : "lg2:max-w-[22.187rem] lg2:border-r"
                      } w-full max-w-full items-center px-4 lg:pb-2.5 pb-1 lg:pt-2.5 pt-2.5 text-sm  border-white/20 text-ellipsis overflow-hidden md:whitespace-nowrap`}
                      title={event.name}
                    >
                      {event.name}
                    </div>

                    {event.id !== "" && (
                      <div className="flex flex-wrap flex-auto">
                        <EventHeader
                          title={moment(event.date).format("ddd, DD MMM YYYY")}
                          Imgpath={CalenderSVG}
                          className="lg:max-w-[9.875rem] max-w-full w-full flex items-center px-3.5 lg:py-3 py-1 text-xs lg:border-r border-white/20"
                          svgClassName="w-[.675rem] h-[.75rem]"
                        />
                        <EventHeader
                          title={moment(event.time, ["h:mm A"]).format("HH:mm")}
                          Imgpath={ClockOutlineSVG}
                          className="lg2:max-w-[5.3125rem] lg:max-w-[5.3125rem] max-w-full w-full flex items-center lg:justify-center px-3 lg:py-3 py-1 text-xs lg:border-r border-white/20"
                          svgClassName="w-[.75rem] h-[.75rem]"
                        />
                        <EventHeader
                          title={event.address}
                          Imgpath={GeoSVG}
                          className="lg:w-auto w-full flex items-center px-4 lg:pt-3 pt-1 lg:pb-3 pb-3 text-xs lg:border-none lg:border-r border-white/20 ellips-text flex-1"
                          svgClassName="w-[.7813rem] h-[.7813rem]"
                        />
                      </div>
                    )}
                  </div> */}
                  <div className="accordion-search flex max-lg:w-full lg2:flex-nowrap flex-wrap lg:flex-1 lg2:mr-4 max-lg2:border-b max-lg2:border-white/20">
                    <div
                      className={`${
                        event.id === ""
                          ? "xl:max-w-[30rem] lg:max-w-[34.31rem] !p-1.5"
                          : "lg2:max-w-[22.187rem] lg:max-w-[15.625rem] md:border-r"
                      } w-full max-w-full items-center px-3 lg:px-4 lg:pb-2.5 pb-1 lg:pt-2.5 pt-2.5 text-sm  border-white/20 text-ellipsis overflow-hidden whitespace-nowrap`}
                      title={event.name}
                    >
                      {event.name}
                    </div>

                    {event.id !== "" && (
                      <div className="flex flex-wrap flex-auto max-lg:px-3">
                        <EventHeader
                          title={moment(event.date).format("ddd, DD MMM YYYY")}
                          Imgpath={CalenderSVG}
                          className="lg:max-w-[9.875rem] max-w-full md:w-full flex items-center lg:px-3.5 py-1 lg:py-3 text-xs lg:border-r border-white/20 max-md:mr-5"
                          svgClassName="w-[.675rem] h-[.75rem] "
                        />
                        <EventHeader
                          title={moment(event.time, ["h:mm A"]).format("HH:mm")}
                          Imgpath={ClockOutlineSVG}
                          className="lg2:max-w-[5.3125rem] lg:max-w-[5.3125rem] max-w-full md:w-full flex items-center lg:justify-center lg:px-3 lg:py-3 py-1 text-xs lg:border-r border-white/20 "
                          svgClassName="w-[.75rem] h-[.75rem]"
                        />
                        <EventHeader
                          title={event.address}
                          Imgpath={GeoSVG}
                          className="lg:w-auto w-full flex items-center lg:px-4 lg:pt-3 pt-1 lg:pb-3 pb-3 text-xs lg:border-none lg:border-r border-white/20 ellips-text lg:flex-1 leading-none"
                          svgClassName="w-[.7813rem] h-[.7813rem]"
                        />
                      </div>
                    )}
                  </div>
                  <div className="lg:w-full lg2:w-auto max-lg2:justify-end flex ml-auto mt-auto">
                    <div className="flex items-center">
                      <div className="flex items-center lg:flex-nowrap flex-wrap max-md:px-3 max-md:gap-y-1 max-md:gap-x-2">
                        {/* TOTAL LOADED EVENTS */}
                        {/* {event?.eventRecords?.length > 0 && (
                          <div
                            id={`${eIndex}-total_loaded_listings`}
                            className="group flex-auto flex items-center mr-3 text-xs text-white "
                          >
                            <Tooltip
                              anchorId={`${eIndex}-total_loaded_listings`}
                              content={`${
                                event?.eventRecords?.length > 1
                                  ? `${addCommaInNumber(
                                      event?.eventRecords?.length
                                    )} listings loaded`
                                  : `${event?.eventRecords?.length} listing loaded`
                              }`}
                              place="top"
                              variant="light"
                              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                            />
                            
                            <IKTSVG
                              className="flex items-center justify-center min-w-[0.75] fill-white mr-1.5 "
                              path={ListingLinesSVG}
                              svgClassName="w-3 h-[.5625rem]"
                            />{" "}
                            Loaded{" "}
                            {addCommaInNumber(event?.eventRecords?.length)}/
                            {addCommaInNumber(event?.total_listings)}
                          </div>
                        )} */}

                        {/* TOTAL LISTING */}
                        <div
                          data-tooltip-id={`${eIndex}-total_listings`}
                          className="group flex items-center md:mr-3 text-xs text-white "
                        >
                          <TooltipPortal
                            id={`${eIndex}-total_listings`}
                            // content={`${event.total_listings} listings`}
                            content={`${
                              event.total_listings > 1
                                ? `${addCommaInNumber(
                                    event.total_listings
                                  )} listings`
                                : `${event.total_listings} listing`
                            }`}
                            place="top"
                            variant="light"
                            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-[12] mt-2.5"
                          />
                          <IKTSVG
                            className="flex items-center justify-center min-w-[0.75] fill-white mr-1.5 "
                            path={ListingLinesSVG}
                            svgClassName="w-3 h-[.5625rem]"
                          />{" "}
                          {addCommaInNumber(event.total_listings)}
                        </div>

                        {/* TOTAL PUBLISHED COUNT */}
                        {event.total_published_listings > 0 && (
                          <div
                            data-tooltip-id={`${eIndex}-total_published_listings`}
                            className="flex items-center md:mr-3 text-xs text-white"
                          >
                            <TooltipPortal
                              id={`${eIndex}-total_published_listings`}
                              content={`${addCommaInNumber(
                                event.total_published_listings
                              )} published`}
                              place="top"
                              variant="light"
                              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-[12] mt-2.5"
                            />
                            <IKTSVG
                              className={`flex items-center justify-center w-[.8125rem] h-[.9375rem] mr-1.5 fill-green-500
                      `}
                              path={haxPublishSVG}
                              svgClassName="w-[.8125rem] h-[.9375rem]"
                            />{" "}
                            {addCommaInNumber(event.total_published_listings)}
                          </div>
                        )}
                        {/* TOTAL UNPUBLISHED COUNT  */}
                        {event.total_unpublished_listings > 0 && (
                          <div
                            data-tooltip-id={`${eIndex}-total_unpublished_listings`}
                            className="flex items-center md:mr-3 text-xs text-white"
                          >
                            <TooltipPortal
                              id={`${eIndex}-total_unpublished_listings`}
                              content={`${addCommaInNumber(
                                event.total_unpublished_listings
                              )} unpublished`}
                              place="top"
                              variant="light"
                              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-[12] mt-2.5"
                            />
                            <IKTSVG
                              className={`flex items-center justify-center w-[.8125rem] h-[.9375rem] mr-1.5 fill-gray-400`}
                              path={unpublish}
                              svgClassName="w-[.8125rem] h-[.9375rem]"
                            />{" "}
                            {addCommaInNumber(event.total_unpublished_listings)}
                          </div>
                        )}

                        {/* ERROR COUNT */}
                        {event.total_error_listings > 0 && (
                          <div
                            data-tooltip-id={`${eIndex}-total_error_listings`}
                            className="flex items-center md:mr-3 text-xs text-white"
                          >
                            <TooltipPortal
                              id={`${eIndex}-total_error_listings`}
                              content={`${
                                event.total_error_listings > 1
                                  ? `${addCommaInNumber(
                                      event.total_error_listings
                                    )} errors`
                                  : `${event.total_error_listings} error`
                              }`}
                              place="top"
                              variant="light"
                              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-[12] mt-2.5"
                            />
                            <IKTSVG
                              className={`flex items-center justify-center w-[.8125rem] h-[.9375rem] mr-1.5
                            fill-rose-500 `}
                              path={warningSVG}
                              svgClassName="w-4 h-4"
                            />{" "}
                            {addCommaInNumber(event.total_error_listings)}
                          </div>
                        )}

                        {/* TOTAL TICKETS */}
                        <div
                          data-tooltip-id={`${eIndex}-total_qty_available`}
                          className="group flex-auto flex items-center xl:mr-5 md:mr-4 text-xs "
                        >
                          <TooltipPortal
                            id={`${eIndex}-total_qty_available`}
                            // content={`${event.total_qty_available} tickets`}
                            content={`${
                              event.total_qty_available > 1
                                ? `${addCommaInNumber(
                                    event.total_qty_available
                                  )} tickets`
                                : `${event.total_qty_available} ticket`
                            }`}
                            place="top"
                            variant="light"
                            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-[12] mt-2.5"
                          />
                          <IKTSVG
                            className="flex items-center justify-center min-w-[0.75] fill-white mr-1.5 "
                            path={subtractionSVG}
                            svgClassName="w-3 h-[.5938rem]"
                          />{" "}
                          {addCommaInNumber(event.total_qty_available)}
                        </div>
                      </div>
                    </div>

                    <TooltipPortal
                      id={`market-insights-tooltip${event?.id}`}
                      content={"Market Insights"}
                      place="top"
                      variant="light"
                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-[12] mt-2.5"
                    />
                    {/* <button
                      className={` flex items-center py-2.5 px-[.8125rem] text-base text-gray-800 text-left border-l border-white/20
                  !transition focus:outline-none group collapsed bg-transparent [&[data-te-collapse-collapsed]]:bg-transparent bottom-arrow [&:not([data-te-collapse-collapsed])]:bg-green-600 [&:not([data-te-collapse-collapsed])]:fill-white 
                  [&:not([data-te-collapse-collapsed])>.arrow]:block relative ${
                    event?.marketInsightLoader ? `pointer-events-none` : ``
                  } mrk-btn `}
                      type="button"
                      data-te-collapse-init
                      data-te-collapse-collapsed
                      data-te-target={`#EventInsightCollapseBody${event?.id}`}
                      aria-expanded="true"
                      aria-controls={`EventInsightCollapseBody${event?.id}`}
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleMarketplaceInsights(event);
                      }}
                    >
                      <div
                        data-tooltip-id={`market-insights-tooltip${event?.id}`}
                      >
                        <IKTSVG
                          className="flex items-center justify-center  fill-green-600 transition hover:!fill-indigo-500 group-[:not([data-te-collapse-collapsed])]:fill-white"
                          path={MarketInsightSVG}
                          svgClassName="w-[.8125rem] h-[.8125rem]"
                        />
                      </div>
                    </button> */}
                    <button
                      id={`EventInsight${event?.id}`}
                      className={` flex items-center pb-2 mt-2 text-base text-gray-800 text-left md:min-w-[7.625rem] 
                      !transition focus:outline-none group collapsed bg-transparent [&[data-te-collapse-collapsed]]:bg-transparent ${
                        !layout.isPublish
                          ? `bottom-arrow  [&:not([data-te-collapse-collapsed])]:fill-white [&:not([data-te-collapse-collapsed])]:bg-gray-750 [&:not([data-te-collapse-collapsed])]:rounded-t   
                      [&:not([data-te-collapse-collapsed])>.arrow]:block `
                          : ``
                      }  relative ${
                        event?.marketInsightLoader ? `pointer-events-none` : ``
                      } ${event?.isOpenMarket && "border_bottom"} mrk-btn `}
                      type="button"
                      data-te-collapse-init
                      data-te-collapse-collapsed
                      data-te-target={`#EventInsightCollapseBody${event?.id}`}
                      aria-expanded="false"
                      aria-controls={`EventInsightCollapseBody${event?.id}`}
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleMarketplaceInsights(event);
                      }}
                    >
                      <div id={`market-insights-tooltip${event?.id}`}>
                        <div className="flex items-center border border-white border-opacity-[13%] bg-white bg-opacity-[7%] p-1.5 md:px-2.5 rounded mkt-btn">
                          {!layout.isPublish && (
                            <>
                              <div className="arrow hidden absolute w-2.5 h-2.5 right-full bottom-0 bg-gray-750">
                                <span className="block w-full h-full bg-violet-700 rounded-br-2xl"></span>
                              </div>
                              <div className="arrow hidden absolute w-2.5 h-2.5 left-full bottom-0 bg-gray-750">
                                <span className="block w-full h-full bg-violet-700 rounded-bl-2xl"></span>
                              </div>
                            </>
                          )}
                          <IKTSVG
                            className="flex items-center justify-center  fill-green-600 transition hover:!fill-indigo-500 group-[:not([data-te-collapse-collapsed])]:fill-violet-500 "
                            path={MarketInsightSVG}
                            svgClassName="w-[.8125rem] h-[.8125rem]"
                          />
                          <span className="max-md:hidden text-sm12 text-white ml-1.5 leading-[1] font-medium group-[:not([data-te-collapse-collapsed])]:text-violet-800">
                            Market Insights
                          </span>
                        </div>
                      </div>
                    </button>

                    <TooltipPortal
                      id={`collapseIcon-${event?.id}`}
                      // content={`${collapse ? `Collapse` : "Expand"}`}
                      content={`${event?.isOpen ? `Collapse` : "Expand"}`}
                      place="top"
                      variant="light"
                      className="!bg-white !opacity-100 !py-0.5 !px-1 !text-xxs font-medium  z-[12]  mt-5 "
                    />
                    <button
                      className="relative flex items-center py-4 lg:px-[.9375rem] px-[.9375rem] text-base text-gray-800 text-left border-white/20 !transition focus:outline-none group collapse-icon "
                      type="button"
                      data-tooltip-id={`collapseIcon-${event?.id}`}
                      data-te-collapse-init
                      data-te-collapse-collapsed
                      data-te-target={`${`#EventCollapseBody${event?.id}`}`}
                      aria-expanded="true"
                      aria-controls={`${`EventCollapseBody${event?.id}`}`}
                      // onClick={() => {
                      //   handleEventId(event.id);
                      //   //handleCollapseAll(event.id);
                      // }}
                    >
                      <IKTSVG
                        className="flex items-center justify-center group-[.collapsed]:rotate-180 fill-white transition group-[[data-te-collapse-collapsed]]:rotate-180 rotate-180}"
                        path={CollapseSVG}
                        svgClassName="w-[.5625rem] h-[.5625rem]"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <MarketInsight event={event} setList={setList} />
              {eventFieldsCompo}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
);

export default EventTable;
